import React, { useCallback, useState, useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import { getSpecificUserEnquiry, getUserEnquiries } from '../../../common/Api'
import { useSelector } from 'react-redux'
import CustomModal from '../../../components/CustomModal'
import { formattedDateTime } from '../../../utils/Utils'
const EnquiryForm = () => {

  const { token } = useSelector((state) => state.login)
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [specificId, setSpecificId] = useState("")
  const [specificData, setSpecificData] = useState("")
  const getData = useCallback(async () => {
    const response = await getUserEnquiries(token)
    if (response && response?.statusCode === 200) {
      setData(response?.data?.get_in_touch_datas)
    }
  }, [token])

  const getSpecificData = async () => {
    const response = await getSpecificUserEnquiry(specificId, token)
    if (response && response?.statusCode === 200) {
      setSpecificData(response?.data)
    }
  }

  useEffect(() => {
    getSpecificData()
  }, [specificId])

  console.log(specificData)


  useEffect(() => {
    getData()
  }, [open])

  console.log(data)

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        }
      }
    },
    {
      name: "name",
      label: "Name"
    },
    {
      name: "email_id",
      label: "Email"
    },
    {
      name: "phone_number",
      label: "Phone Number"
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => { return <div>{!value ? "Unread" : "Read"}</div> }
      }
    },
    {
      name: "message",
      label: "Message",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className='d-flex align-items-center justify-content-center'>
              <i
                class="fa-solid fa-eye admin-view-icon pointer"
                onClick={() => {
                  setSpecificId(tableMeta.rowData[0])
                  setOpen(true)
                }}
              ></i>
            </div>
          )
        }
      }
    }
  ]

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
  };

  return (
    <div className=''>
      <MUIDataTable
        title={"User Enquiries List"}
        data={data}
        columns={columns}
        options={options}
      />
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content enquiryModal">
          <div className=''>
            <h3 className='mb-4'>{`${specificData?.name}'s enquiry details`}</h3>
          </div>
          <div className='row g-5'>
            <div className='col-lg-6'>
              <label>Name</label><br />
              <input value={specificData?.name} disabled={true} className='form-control' />
            </div>
            <div className='col-lg-6'>
              <label>Email Id</label><br />
              <input value={specificData?.email_id} disabled={true} className='form-control' />
            </div>
            <div className='col-lg-6'>
              <label>Phone Number</label><br />
              <input value={specificData?.phone_number} disabled={true} className='form-control' />
            </div>
            <div className='col-lg-6'>
              <label>Date and Time</label><br />
              <input value={formattedDateTime(specificData?.createdAt)} disabled={true} className='form-control' />
            </div>
            <div className='col-lg-12'>
              <label>Message</label>
              <textarea value={specificData?.message} disabled={true} className='form-control' />
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default EnquiryForm