import React from 'react'
import { useNavigate } from 'react-router-dom'

const UnAuthorized = () => {
  const navigate = useNavigate()
  return (
    <div className=''>
      <p className='text-center mt-5'>Sorry! you don't Have permission to access this page</p>
      <p className='text-center'>GO Back To <a className='pointer' onClick={() => navigate(-1)}>Previous Page</a></p>
    </div>
  )
}

export default UnAuthorized