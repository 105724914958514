import React from 'react'
import ForgotPassword from '../../features/user/ForgotPassword'

const ExternalPage = () => {
    return (
        <div>
            <ForgotPassword />
        </div>
    )
}

export default ExternalPage