import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Welcome from "./pages/public/Welcome";
import Login from "./pages/public/Login";
import ForgotPassword from "./pages/public/ForgotPassword";
import Layout from "./containers/Layout";
import VerifyOtp from "./features/user/VerifyOtp";
import ChangePassword from "./features/user/ChangePassword";
import { useSelector } from "react-redux";
import NotFound from "./features/NotFound";
import ExpertLogin from "./features/user/ExpertLogin";
import ExpertVerifyOtp from "./features/user/ExpertVerifyOtp";
import UnAuthorized from "./features/UnAuthorized";
function App() {
  const { isLoggedIn, userType } = useSelector((state) => state?.login)
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/admin-login" element={!isLoggedIn ? <Login /> : <Navigate replace to="/admin/dashboard" />} />
          <Route path="/expert-login" element={!isLoggedIn ? <ExpertLogin /> : <Navigate replace to="/expert/app/dashboard" />} />
          <Route path="/expert-verifyOtp" element={<ExpertVerifyOtp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verifyOtp" element={<VerifyOtp />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/admin/*" element={(isLoggedIn && userType === "admin") ? <Layout /> : <UnAuthorized />} />
          {/* <Route path="/expert/*" element={(isLoggedIn && userType === "user") ? <Layout /> : <UnAuthorized />} /> */}
          <Route path="/expert/*" element={<Layout/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
