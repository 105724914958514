import React, { useState, useRef, useEffect } from 'react'
import LoginImg from '../../assets/images/login-img.png'
import OtpInput from "react-otp-input";
import Logo from '../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import { errorMsg, successMsg } from '../../containers/Toastify'
import { useDispatch, useSelector } from 'react-redux'
import { loginSuccess } from '../../store/slice/loginSlice'
import { ExpertWebVerifyOtp } from '../../common/Api'
import 'react-phone-number-input/style.css'
const ExpertVerifyOtp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const Ref = useRef(null);
  const { mobileNumber, countryCode } = useSelector((state) => state?.login)
  const [otp, setOtp] = useState("")
  const [validation, setValidation] = useState({})
  const [timer, setTimer] = useState("00:00");
  const [error, setError] = useState("")
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);

    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:05");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 5);
    return deadline;
  };
  const onClickReset = () => {
    handleSubmitResend();
    clearTimer(getDeadTime());
  };
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (otp) {
      setError()
      const payload = {
        phone_number: mobileNumber,
        country_code: countryCode,
        otp: otp
      }
      console.log(payload)
      const response = await ExpertWebVerifyOtp(payload)
      if (response && response.statusCode === 200) {
        console.log(response?.data)
        dispatch(loginSuccess(response?.data))
        navigate('/expert/app/dashboard')
        successMsg(response?.message)
      } else {
        errorMsg(response?.message)
      }

    } else {
      setError("OTP Is Required")
    }

  }


  const handleSubmitResend = async () => {
    const Payload = {
      email_id: ""
    };
    // const response = await AdminForgotPassword(Payload);
    // if (response && response.statusCode === 200) {
    //     successMsg(response.message);
    // } else {
    //     errorMsg(response.message);
    // }
  };

  return (
    <div className='login-bg'>
      <div className='container'>
        <div className='row gx-5'>
          <div className='col-lg-6'>
            <div className='d-flex align-items-center justify-content-center flex-column'>
              <img className='' src={Logo} alt='guy-with-laptop' />
              <img className='' src={LoginImg} alt='guy-with-laptop' />
            </div>
          </div>
          <div className='col-lg-6 d-flex flex-column justify-content-center'>
            <div className='login-layout'>
              <div className='text-start  mb-4'>
                <p className='h1 text-white fw-bold'>Verify Your Phone</p>
                <small className='text-white'>A 4 digit OTP code has been sent to {mobileNumber}. Enter the code to continue.</small>
              </div>
              <div className='w-100'>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="otpSpace">
                    <p className='text-white'>Enter 4 Digit Code</p>
                    <OtpInput
                      className=""
                      value={otp}
                      onChange={(e) => setOtp(e)}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                      renderSeparator={<span className="justify-content-between"></span>}
                      inputStyle={{
                        border: "1px solid #CFD3DB",
                        borderRadius: "8px",
                        width: "45px",
                        height: "45px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                        fontSize: "18px"
                      }}
                      focusStyle={{
                        border: "1px solid #3773C9",
                        outline: "none",
                      }}
                      separator={<span style={{ width: "8px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      name="otpValue"
                    />
                  </div>
                  {error ?
                    <small className='text-center validation-error'>
                      {error}
                    </small> : ""
                  }
                  <div className="mt-5">
                    <button className="btn login-btn w-100 mb-4">Verify OTP</button>
                  </div>
                  <div className="displayflexspace">
                    <button
                      className="text-start resendBtn"
                      onClick={onClickReset}
                      disabled={timer !== "00:00"}
                    >
                      <small className="text-white">Resend OTP {timer === "00:00" ? "" : `in ${timer}`}</small>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpertVerifyOtp