import React from 'react'
import { useSelector } from 'react-redux'

const AdminNotFound = () => {
    const { userType } = useSelector((state) => state?.login)
    const redirectPath = userType === "admin" ? '/admin/dashboard' : '/expert/dashboard'

    return (
        <div className=''>
            <p className='text-center text-white'>Page Not Found</p>
            <p className='text-center text-white'>GO Back To <a href={redirectPath} className=''>Dashboard</a></p>
        </div>
    )
}

export default AdminNotFound