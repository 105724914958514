import React, { useState } from 'react'
import LoginImg from '../../assets/images/login-img.png'
import Logo from '../../assets/images/logo.png'
import { EmailValidation, PasswordValidation } from '../../common/validate'
import { useNavigate } from 'react-router-dom'
import { errorMsg, successMsg } from '../../containers/Toastify'
import { useDispatch } from 'react-redux'
import { loginSuccess, registerExpertData } from '../../store/slice/loginSlice'
import { AdminLogin, ExpertWebLogin } from '../../common/Api'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { parsePhoneNumber } from 'libphonenumber-js';
const ExpertLogin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [userData, setUserData] = useState({})
  const [validation, setValidation] = useState({})
  console.log(validation)
  const handleChnage = (value, key) => {
    setUserData({
      ...userData,
      [key]: value
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (userData?.phoneNumber) {
      const parsedNumber = parsePhoneNumber(userData?.phoneNumber);
      setValidation()
      const payload = {
        country_code: parsedNumber.countryCallingCode,
        phone_number: parsedNumber.nationalNumber,
        login_from: "mobile"
      }
      console.log(payload)
      const response = await ExpertWebLogin(payload)

      if (response && response.statusCode === 200) {
        dispatch(registerExpertData(payload))
        navigate('/expert-verifyOtp')
        successMsg(response?.message)
      } else {
        errorMsg(response?.message)
      }

    } else {
      setValidation({
        status: false,
        message: "Phone Number Is Required"
      })
    }

  }

  return (
    <div className='login-bg'>
      <div className='container'>
        <div className='row gx-5'>
          <div className='col-lg-6'>
            <div className='d-flex align-items-center justify-content-center flex-column'>
              <img className='' src={Logo} alt='guy-with-laptop' />
              <img className='' src={LoginImg} alt='guy-with-laptop' />
            </div>
          </div>
          <div className='col-lg-6 d-flex flex-column justify-content-center'>
            <div className='login-layout'>
              <div className='text-center'>
                <p className='h4 text-white'>Login</p>
                <small className='text-white'>Login Only for Expert</small>
              </div>
              <div className='w-100'>
                <div className='my-4'>
                  <label className='text-white mb-2'>
                    Phone Number
                  </label>
                  <PhoneInput
                    defaultCountry="IN"
                    value={userData?.phoneNumber}
                    onChange={(e) => handleChnage(e, "phoneNumber")}
                  />
                  <small className='validatior-error'>{validation?.message || ' '}</small>
                </div>
                <div className='text-center'>
                  <button
                    className='btn login-btn w-100 mt-4'
                    onClick={handleSubmit}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpertLogin