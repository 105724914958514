import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
const SubMenu = ({ name, icon, subMenu }) => {
    const [isExpanded, setEaxpanded] = useState(false)
    const navigate = useNavigate();
    const { pathname } = useLocation()

    const handleClick = (path, index) => {
        navigate(path);
    };

    const arrowStyle = {
        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s ease-in-out",
        cursor: "pointer",
    };

    return (
        <div className='d-flex flex-column justify-content-between'>
            <div
                className={`sidebar-menu-wrapper align-items-center justify-content-between`}
                onClick={() => setEaxpanded(!isExpanded)}
            >
                <div className='d-flex align-items-center'>
                    <div className='sidebar-menu'>
                        <p>
                            <span className='sidebar-icon'>{icon}</span>
                        </p>
                    </div>
                    <p className='mb-0'>{name}</p>
                </div>
                <div className=''>
                    <i className="fa-solid fa-chevron-down pe-2" style={arrowStyle}></i>
                </div>
            </div>
            {isExpanded ?
                <div className=''>
                    {subMenu?.map((item, i) => (
                        <div
                            key={i}
                            className={`sidebar-menu-wrapper ms-4 ${item?.path === pathname ? 'clicked' : ''}`}
                            onClick={() => handleClick(item?.path, i)}
                        >
                            <div className='sidebar-menu'>
                                <p>
                                    <span className='sidebar-icon'>{item?.icon}</span>
                                </p>
                            </div>
                            <p className='mb-0 tootip-tes'>{item.name}</p>
                        </div>
                    ))}
                </div> : ""}
        </div>
    )
}

export default SubMenu