import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/CustomModal";

const Category = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const columns = [
    {
      name: "S.No",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "subject",
      label: "SUBJECT",
    },

    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <i class="fa-solid fa-pen-to-square update_btn_icon"></i>
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <i class="fa-solid fa-trash-arrow-up admin-delete-icon"></i>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      subject: "stock",
    },
    {
      subject: "intraday",
    },
    {
      subject: "scalping",
    },
    {
      subject: "put",
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  return (
    <>
      <div className="admin_btn_right">
        <button className="admin-btn" onClick={() => setOpen(true)}>
          Create Category
        </button>
      </div>
      <div className="">
        <MUIDataTable
          title={"User Enquiries List"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content logout-modal p-5">
          <p className="text-center mb-5 text-white">
            Are you sure do you want to delete ?
          </p>
          <div className="row">
            <div className="col-6">
              <button className="btn ok-btn">Yes</button>
            </div>
            <div className="col-6">
              <button className="btn cancel-btn text-green">No</button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Category;
