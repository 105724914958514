import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { PasswordValidation } from '../../common/validate'
import { AdminChangePassword } from '../../common/Api'
import { errorMsg, successMsg } from '../../containers/Toastify'
import { useNavigate } from 'react-router-dom'
const ChangePassword = () => {
  const { token } = useSelector((state) => state?.login)
  const navigate = useNavigate()
  const [userData, setUserData] = useState({})
  const [validation, setValidation] = useState({})
  const [viewPassword, setViewPassword] = useState({})
  console.log(validation)
  const handleChange = (e, key) => {
    setUserData({
      ...userData,
      [key]: e
    })
  }
  const handleEyeClick = (key, value) => {
    setViewPassword({
      ...viewPassword,
      [key]: value
    })
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    let validate = {}
    validate.oldPassword = PasswordValidation(userData?.oldPassword)
    validate.newPassword = PasswordValidation(userData?.newPassword)
    validate.reEnterNewPAssword = PasswordValidation(userData?.reEnterNewPAssword)
    setValidation(validate)

    const checkInput = Object.values(validation).every((a) => a.status === true)
    if (checkInput) {
      const payload = {
        password: userData?.oldPassword,
        new_password: userData?.newPassword
      }
      const response = await AdminChangePassword(payload, token)
      if (response && response.statusCode === 200) {
        successMsg(response?.message)
        navigate(-1)
      } else {
        errorMsg("Old password is inCorrect")
      }

    }
  }

  return (
    <div className='admin-content-body'>
      <div className='admin-form-heading d-flex justify-content-between align-items-center'>
        <p className='mb-0'>Change Password</p>
        <button className='admin-btn' onClick={handleSubmit}>Update</button>
      </div>
      <div className='Admin-form-content'>
        <div className='admin-form-container w-25 input'>
          <input
            type={`${viewPassword?.oldPassword ? 'text' : 'password'}`}
            className='form-control admin-panel-input'
            placeholder='Enter Old Password'
            defaultValue={userData?.oldPassword}
            onInput={(e) => handleChange(e.target.value, 'oldPassword')}
          />
          {
            viewPassword?.oldPassword ?
              <i
                className="fa-solid fa-eye change-password-eye  top-15"
                onClick={() => handleEyeClick("oldPassword", false)}
              ></i> :
              <i className="fa-solid fa-eye-slash change-password-eye  top-15"
                onClick={() => handleEyeClick("oldPassword", true)}
              ></i>
          }
          {validation?.oldPassword?.message ?
            <small className='validation-error'>
              {`Old ${validation?.oldPassword?.message}`}
            </small> : ""
          }
        </div>
        <div className='admin-form-container input w-25'>
          <input
            type={`${viewPassword?.newPassword ? 'text' : 'password'}`}
            placeholder='Enter New Password'
            className='form-control admin-panel-input'
            defaultValue={userData?.newPassword}
            onInput={(e) => handleChange(e.target.value, 'newPassword')}
          />
          {
            viewPassword?.newPassword ?
              <i
                className="fa-solid fa-eye change-password-eye  top-15"
                onClick={() => handleEyeClick("newPassword", false)}
              ></i> :
              <i className="fa-solid fa-eye-slash change-password-eye  top-15"
                onClick={() => handleEyeClick("newPassword", true)}
              ></i>
          }
          {validation?.newPassword?.message ?
            <small className='validation-error'>
              {`new ${validation?.newPassword?.message}`}
            </small> : ""
          }
        </div>
        <div className='admin-form-container input w-25'>
          <input
            type={`${viewPassword?.reEnterNewPAssword ? 'text' : 'password'}`}
            className='form-control admin-panel-input'
            placeholder='Re-enter New Password'
            defaultValue={userData?.reEnterNewPAssword}
            onInput={(e) => handleChange(e.target.value, 'reEnterNewPAssword')}
          />
          {
            viewPassword?.reEnterNewPAssword ?
              <i
                className="fa-solid fa-eye change-password-eye  top-15"
                onClick={() => handleEyeClick("reEnterNewPAssword", false)}
              ></i> :
              <i className="fa-solid fa-eye-slash change-password-eye  top-15"
                onClick={() => handleEyeClick("reEnterNewPAssword", true)}
              ></i>
          }
          {validation?.reEnterNewPAssword?.message ?
            <small className='validation-error'>
              {`Re-entered ${validation?.reEnterNewPAssword?.message}`}
            </small> : ""
          }
        </div>
      </div>
    </div>
  )
}

export default ChangePassword