import React from "react";
import MUIDataTable from "mui-datatables";
const Subcategory = () => {
  const columns = [
    {
      name: "S.No",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "TITLE",
    },
    {
      name: "query",
      label: "QUERY",
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <i class="fa-solid fa-pen-to-square update_btn_icon"></i>
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <i class="fa-solid fa-trash-arrow-up admin-delete-icon"></i>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      title: "stock",
      query: "stock",
    },
    {
      title: "intraday",
      query: "stock",
    },
    {
      title: "scalping",
      query: "scalping",
    },
    {
      title: "scalping",
      query: "put",
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  return (
    <>
      <div className="admin_btn_right">
        <button className="admin-btn">Create SubCategory</button>
      </div>
      <div className="">
        <MUIDataTable
          title={"User Enquiries List"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
};

export default Subcategory;
