import React from 'react'

const NotFound = () => {
  return (
    <div className=''>
        <p className='text-center'>Page Not Found</p>
        <p className='text-center'>GO Back To <a href='/' className=''>Home</a></p>
    </div>
  )
}

export default NotFound