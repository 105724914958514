import React, { useState } from "react";
import user from "../assets/images/usr.png";
import Logo from "../assets/images/logo-white.png";
import Logo2 from "../assets/images/logo-whit.png";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../components/CustomModal";
import { logoutSuccess } from "../store/slice/loginSlice";
import { useNavigate } from "react-router-dom";
const Header = () => {
    const { userType } = useSelector((state) => state?.login)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const logout = () => {
        dispatch(logoutSuccess())
        navigate(userType === "admin" ? "/admin-login" : "/")
    }
    return (
        <div className='header'>
            <div className=''>
                <img src={userType === "admin" ? Logo2 : Logo} alt='logo' className='admin-logo' />
            </div>
            <div className="dropdown ">
                <div
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <img src={user} alt='user' className='profile-image pointer' />
                </div>
                <ul className="dropdown-menu">
                    <li onClick={() => navigate('/admin/profileUpdate')} className="pointer">My Profile</li>
                    <li onClick={() => navigate('/admin/changePassword')} className='pointer'>Change Password</li>
                    <li onClick={() => setOpen(true)} className='pointer'>Logout</li>
                </ul>
            </div>
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content logout-modal p-5">
                    <p className='text-center mb-5 text-white'>Are you sure want to logout?</p>
                    <div className='row'>
                        <div className='col-6'>
                            <button
                                className='btn ok-btn'
                                onClick={logout}
                            >Yes</button>
                        </div>
                        <div className='col-6'>
                            <button className='btn cancel-btn text-green'>Cancel</button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}

export default Header;
