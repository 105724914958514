import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmailValidation, NonEmptyValidation, PasswordValidation, PhoneNumberValidation } from '../../../common/validate'
import { createAdmin, findSpecificAdmin, updateAdmin } from "../../../common/Api";
import { useSelector } from "react-redux";
import { successMsg, errorMsg } from '../../../containers/Toastify';

const CreateAdmin = () => {
  const { id } = useParams()
  const { token } = useSelector((state) => state?.login)
  const navigate = useNavigate()
  const [userData, setUserData] = useState({})
  const [validation, setValidation] = useState({})
  console.log(validation)
  console.log(userData)
  const handleChange = (e, key) => {
    setUserData({
      ...userData,
      [key]: e
    })
  }

  const getData = async () => {
    const response = await findSpecificAdmin(id, token)
    if (response && response?.statusCode === 200) {
      const res = response?.data
      setUserData({
        name: res.name,
        phoneNumber: res?.phone_number,
        email: res?.email_id
      })
    }
  }

  useEffect(() => {
    getData()
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let validate = {}
    validate.name = NonEmptyValidation(userData?.name)
    validate.phoneNumber = PhoneNumberValidation(userData?.phoneNumber)
    validate.email = EmailValidation(userData?.email)
    setValidation(validate)
    const checkInput = Object.values(validate).every((a) => a.status === true)
    console.log(checkInput)
    if (checkInput) {
      const payload = {
        _id: id,
        name: userData?.name,
        phone_number: userData?.phoneNumber,
      }
      const response = await updateAdmin(payload, token)
      if (response && response.statusCode === 200) {
        successMsg(response?.message)
        navigate(-1)
      } else {
        errorMsg(response?.message)
      }
    }
  }

  return (
    <div className='admin-content-body'>
      <div className='admin-form-heading d-flex justify-content-between align-items-center'>
        <p className='mb-0'>
          <i class="fa-solid fa-arrow-left me-3 pointer" onClick={() => navigate(-1)}></i>
          Edit Admin
        </p>
        <button className='admin-btn' onClick={handleSubmit}>Update</button>
      </div>
      <div className='Admin-form-content'>
        <div className="row">
          <div className="col-lg-6">
            <div className="admin_input mb-3">
              <label>
                Name<span className="ms-1">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter Name"
                defaultValue={userData?.name}
                onInput={(e) => handleChange(e.target.value, "name")}
              />
              {validation?.name?.message ?
                <small className='validation-error'>
                  {`Name ${validation?.name?.message}`}
                </small> : ""
              }
            </div>
          </div>
          <div className="col-lg-6">
            <div className="admin_input">
              <label>
                Phone Number<span className="ms-1">*</span>
              </label>
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Enter Phone Number"
                defaultValue={userData?.phoneNumber}
                onInput={(e) => handleChange(e.target.value, "phoneNumber")}
              />
              {validation?.phoneNumber?.message ?
                <small className='validation-error'>
                  {`phone Number ${validation?.phoneNumber?.message}`}
                </small> : ""
              }
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="admin_input">
              <label>
                Email<span className="ms-1">*</span>
              </label>
              <input
                type="email"
                name="email"
                disabled={true}
                placeholder="Enter Email"
                autoComplete="off"
                defaultValue={userData?.email}
                onInput={(e) => handleChange(e.target.value, "email")}
              />
              {validation?.email?.message ?
                <small className='validation-error'>
                  {`Email ${validation?.email?.message}`}
                </small> : ""
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
