import React from 'react';
import { menu, ExpertMenu } from '../routes/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import SubMenu from './SubMenu';
import { useSelector } from 'react-redux';

const Sidebar = () => {
    const { userType } = useSelector((state) => state.login)
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const handleClick = (path, index) => {
        navigate(path);
    };

    let sidebarMenu
    if (userType === "user") {
        sidebarMenu = ExpertMenu
    } else if (userType === "admin") {
        sidebarMenu = menu
    }

    return (
        <div className='sidebar'>
            <div className='menus'>
                {sidebarMenu?.map((item, i) => {
                    return (
                        item?.subMenu ?
                            <SubMenu {...item} key={i + 10} />
                            :
                            <>
                                {item.title ?
                                    <div className='sidebar-header' key={i}>{item?.title}</div> : ""}
                                <div
                                    key={i + 20}
                                    className={`sidebar-menu-wrapper ${item?.path === pathname ? 'clicked' : ''}`}
                                    onClick={() => handleClick(item?.path, i)}
                                >
                                    <div className='sidebar-menu'>
                                        <p>
                                            <span className='sidebar-icon'>{item?.icon}</span>
                                        </p>
                                    </div>
                                    <p className='mb-0 tootip-tes'>{item.name}</p>
                                </div>
                            </>
                    )
                })}
            </div>
        </div>
    );
};

export default Sidebar;
