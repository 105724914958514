import React, { useState } from 'react'
import LoginImg from '../../assets/images/login-img.png'
import Logo from '../../assets/images/logo.png'
import { EmailValidation, PasswordValidation } from '../../common/validate'
import { useNavigate } from 'react-router-dom'
import { errorMsg, successMsg } from '../../containers/Toastify'
import { useDispatch } from 'react-redux'
import { loginSuccess } from '../../store/slice/loginSlice'
import { AdminLogin } from '../../common/Api'
const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [userData, setUserData] = useState({})
  const [validation, setValidation] = useState({})
  const [viewPassword, setViewPassword] = useState(false)
  const handleChnage = (value, key) => {
    setUserData({ ...userData, [key]: value })
    if (validation[key]) setValidation({ ...validation, [key]: false })
  }

  const handleEyeClick = () => {
    setViewPassword(!viewPassword)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let validate = {}
    validate.emailId = EmailValidation(userData?.emailId, 'Email Id')
    validate.password = PasswordValidation(userData?.password, 'Password')
    setValidation(validate)
    const isNotError = Object.values(validate).every((v) => v.status === true)
    if (isNotError) {
      const payload = {
        email_id: userData?.emailId,
        password: userData?.password
      }
      const response = await AdminLogin(payload)

      if (response && response.statusCode === 200) {
        dispatch(loginSuccess(response?.data))
        navigate('/expert/dashboard')
        successMsg('Login Successful')
      } else {
        errorMsg('Invalid Credentials')
      }

    }

  }

  return (
    <div className='login-bg'>
      <div className='container'>
        <div className='row gx-5'>
          <div className='col-lg-6'>
            <div className='d-flex align-items-center justify-content-center flex-column'>
              <img className='' src={Logo} alt='guy-with-laptop' />
              <img className='' src={LoginImg} alt='guy-with-laptop' />
            </div>
          </div>
          <div className='col-lg-6 d-flex flex-column justify-content-center'>
            <div className='login-layout'>
              <div className='text-center'>
                <p className='h4 text-white'>Login</p>
              </div>
              <div className='w-100'>
                <div className='my-4'>
                  <label className='text-white mb-2'>
                    Email Address *
                  </label>
                  <input
                    name='email'
                    type='email'
                    onChange={(e) => handleChnage(e.target.value, "emailId")}
                    placeholder='Enter Email Address'
                    className='form-control login-input'
                  />
                  <small className='validatior-error'>{validation?.emailId?.message || ' '}</small>
                </div>
                <div className='my-4'>
                  <label className='text-white mb-2'>
                    Password *
                  </label>
                  <div className='input'>
                    <input
                      name='password'
                      type={`${viewPassword ? 'text' : 'password'}`}
                      onChange={(e) => handleChnage(e.target.value, "password")}
                      placeholder='Enter Password'
                      className='form-control login-input'
                    />
                    {
                      viewPassword ?
                        <i className="fa-solid fa-eye login-eye" onClick={handleEyeClick}></i> :
                        <i className="fa-solid fa-eye-slash login-eye" onClick={handleEyeClick}></i>
                    }
                  </div>
                  <small className='validatior-error'>{validation?.password?.message || ' '}</small>
                  <div className='text-end'>
                    <small
                      className='text-end text-white forgot-password-text'
                      onClick={() => navigate('/forgot-password')}
                    >Forgot Password?</small>
                  </div>
                </div>
                <div className='text-center'>
                  <button
                    className='btn login-btn w-100 mt-4'
                    onClick={handleSubmit}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login