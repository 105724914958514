import React, { useState } from "react";
import docs_img from "../../../assets/images/chart_img.png";
import CustomModal from "../../../components/CustomModal";
import expert_img from "../../../assets/images/expert_profile_img.png";
import stock_img from "../../../assets/images/stock_img.png";

const Tips = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Today Tip", "Scheduled Tip"];
  const hanldeClickTabs = (index) => {
    setActiveTab(index);
  };
  const [buyIndicator, setbuyIndicator] = useState();
  const [optionstype, setOptionstype] = useState();
  const [isOptionOn, setisOptionOn] = useState();
  const indicator = ["Buy", "Sell"];
  const options = ["Monthly", "weekly"];
  const opt_indicator = ["Call(CE)", "Put(PE)"];

  const categorystocks = ["Equity", "Index", "Forex", "Commodity", "Crypto"];
  // type of stocks options
  const equity = ["Intraday", "Options", "Future", "Long Term", "Short term"];
  const index = ["Options", "Future"];
  const forex_commodity = ["Intraday", "Options", "Future"];
  const crypto = ["Intraday", "Long Term", "Short term"];
  //
  const [open, setOpen] = useState(false);
  const handleFilter = () => {
    setOpen(!open);
  };

  // function for tips

  const [stockCategory, setstockCategory] = useState({
    category_of_stocks: "",
    type_of_stocks: "",
  });
  console.log(stockCategory, "stockcategory");
  const handleCategory = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setstockCategory({ ...stockCategory, [name]: value });
  };

  const handleClickCategory = (category) => {};
  //
  return (
    <>
      <div>
        <div className="d-flex mb-2">
          {tabs.map((item, index) => {
            return (
              <>
                <div
                  className={
                    activeTab === index
                      ? "tip_tabs_active d-flex me-3"
                      : "tip_tabs d-flex me-3"
                  }
                  key={index}
                  onClick={(e) => hanldeClickTabs(index)}
                >
                  <i class="fa-solid fa-record-vinyl mt-1 me-2"></i>
                  <p className="mb-0 tipsTab">{item}</p>
                </div>
              </>
            );
          })}
        </div>
      </div>
      {activeTab === 0 && (
        <div className="today_tips">
          <div className="me-2">
            <div className="row">
              <div className="col-lg-12">
                <input
                  name="market"
                  className="express_search"
                  placeholder="Search Stock Name"
                />
              </div>
            </div>
          </div>
          <div className="expert_express_tips">
            <div className="me-2 mt-2">
              <div className="row">
                <div className="col-lg-9">
                  <div className="cat_stocks">
                    <select
                      name="category_of_stocks"
                      className="cat_stocks_select"
                      onChange={handleCategory}
                    >
                      <option disabled selected>
                        Category of Stocks
                      </option>
                      {categorystocks.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="exp_stock">
                    <input
                      name="expires_from"
                      type={
                        stockCategory.type_of_stocks === "Intraday"
                          ? "time"
                          : "date"
                      }
                      className="expires_time"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-9 mt-2">
                  <select
                    name="type_of_stocks"
                    className="cat_stocks_select"
                    onChange={handleCategory}
                    disabled={
                      stockCategory.category_of_stocks === "" ? true : false
                    }
                  >
                    <option disabled selected>
                      Type of Stocks
                    </option>
                    {stockCategory.category_of_stocks === "Equity" &&
                      equity.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    {stockCategory.category_of_stocks === "Index" &&
                      index.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    {(stockCategory.category_of_stocks === "Forex" ||
                      stockCategory.category_of_stocks === "Commodity") &&
                      forex_commodity.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}

                    {stockCategory.category_of_stocks === "Crypto" &&
                      crypto.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-lg-3 mt-2">
                  <div className="Enter Price">
                    <input
                      name="expires_to"
                      type={
                        stockCategory.type_of_stocks === "Intraday"
                          ? "time"
                          : "date"
                      }
                      className="expires_time"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </div>
            </div>
            {stockCategory.type_of_stocks === "Options" && (
              <>
                <div className="me-2">
                  <div className="row">
                    {options.map((item, index) => {
                      return (
                        <>
                          <div className="col-lg-3 mt-2">
                            <div
                              className="exp_stock"
                              onClick={(e) => {
                                if (
                                  !(
                                    index === 1 &&
                                    stockCategory.category_of_stocks ===
                                      "Equity"
                                  )
                                ) {
                                  setisOptionOn(index);
                                }
                              }}
                              key={index}
                            >
                              <p
                                className={
                                  isOptionOn === index
                                    ? "exp_stock_para_active"
                                    : index === 1 &&
                                      stockCategory.category_of_stocks ===
                                        "Equity"
                                    ? "exp_stock_para_disabled"
                                    : "exp_stock_para"
                                }
                              >
                                {item}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {opt_indicator.map((item, index) => {
                      return (
                        <>
                          <div className="col-lg-3 mt-2">
                            <div
                              className="exp_stock"
                              onClick={(e) => setOptionstype(index)}
                              key={index}
                            >
                              <p
                                className={
                                  optionstype === index
                                    ? "exp_stock_para_active"
                                    : "exp_stock_para"
                                }
                              >
                                {item}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <div className="me-2">
              <div className="row">
                {indicator.map((item, index) => {
                  return (
                    <>
                      <div className="col-lg-3 mt-2">
                        <div
                          className="exp_stock"
                          onClick={(e) => setbuyIndicator(index)}
                          key={index}
                        >
                          <p
                            className={
                              buyIndicator === index
                                ? "exp_stock_para_active"
                                : "exp_stock_para"
                            }
                          >
                            {item}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="col-lg-3 mt-2">
                  <div className="">
                    <p className="only_price">Only Price@ </p>
                  </div>
                </div>
                <div className="col-lg-3 mt-2">
                  <div className="Enter Price">
                    <input
                      name="market"
                      type="number"
                      className="express_search"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="me-2 mt-2">
              <button className="buy_only_btn">Buy Only Price - ${10}</button>
            </div>
            <div className="me-2 mt-2">
              <select
                name="scenerio"
                id="scenerio"
                className="choose_scenario_dropdown"
              >
                <option value="volvo" disabled selected>
                  Choose Scenerio
                </option>
                <option>Option1</option>
                <option>Option1</option>
                <option>Option1</option>
                <option>Option1</option>
                <option>Option1</option>
              </select>
            </div>
            <div className="me-2 mt-3">
              <div className="row">
                <div className="col-lg-6">
                  <div className="target_level_div">
                    <h5 className="target_head">Target Level</h5>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      className="mt-2"
                    >
                      <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                      <p className="head-para2">
                        Target Levels are appropriate, user can exit the trade
                        wisely based on profit or loss.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">T1-Low</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">T2-Medium</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">T3-High</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="target_level_div">
                    <h5 className="target_head">Stop Loss Level</h5>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      className="mt-2"
                    >
                      <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                      <p className="head-para2">
                        Stoploss Levels are appropriate, user can exit the trade
                        wisely based on profit or loss.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">S1-Low</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">S2-Medium</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">S3-High</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 me-2">
              <h5 className="target_head">Chart</h5>
              <div className="row">
                <div className="col-lg-12">
                  <div className="chart_upload d-flex">
                    <div className="d-flex chart_div me-2">
                      <div>
                        <img src={docs_img} alt="" className="chart_img me-2" />
                      </div>
                      <form>
                        <input
                          type="file"
                          name="filename"
                          className="chart_input"
                        />
                      </form>
                    </div>
                    <div className="chart_upload_icon">
                      <i class="fa-solid fa-upload"></i>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="mt-2"
                  >
                    <i className="fa-solid fa-circle-info me-1 fa-circle-info3"></i>
                    <p className="head-para2" style={{ color: "#5E6871" }}>
                      Attached Refer Chart Showing Target & Stop Loss Level
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 me-2">
              <div className="row">
                <div className="col-lg-6">
                  <div className="create_tip_card2 mt-2" onClick={setOpen}>
                    <p>Preview</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="create_tip_card3 mt-2">
                    <p>Submit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* schelude TIPS */}
      {activeTab === 1 && (
        <div className="today_tips">
          <div className="me-2">
            <div className="row">
              <div className="col-lg-12">
                <input
                  name="market"
                  className="express_search"
                  placeholder="Search Stock Name"
                />
              </div>
            </div>
          </div>
          <div className="expert_express_tips">
            <div className="me-3 mt-2">
              <div className="row">
                <div className="col-lg-6">
                  <div className="cat_stocks">
                    <select
                      name="category_of_stocks"
                      className="cat_stocks_select"
                      onChange={handleCategory}
                    >
                      <option disabled selected>
                        Category of Stocks
                      </option>
                      {categorystocks.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="exp_stock">
                    <input
                      name="expires_from"
                      type="date"
                      className="expires_time"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="exp_stock">
                    <input
                      name="expires_from"
                      type="time"
                      className="expires_time"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-2">
                  <select
                    name="type_of_stocks"
                    className="cat_stocks_select"
                    onChange={handleCategory}
                    disabled={
                      stockCategory.category_of_stocks === "" ? true : false
                    }
                  >
                    <option disabled selected>
                      Type of Stocks
                    </option>
                    {stockCategory.category_of_stocks === "Equity" &&
                      equity.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    {stockCategory.category_of_stocks === "Index" &&
                      index.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    {(stockCategory.category_of_stocks === "Forex" ||
                      stockCategory.category_of_stocks === "Commodity") &&
                      forex_commodity.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}

                    {stockCategory.category_of_stocks === "Crypto" &&
                      crypto.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-lg-3 mt-2">
                  <div className="Enter Price">
                    <input
                      name="expires_to"
                      type={
                        stockCategory.type_of_stocks === "Intraday"
                          ? "time"
                          : "date"
                      }
                      className="expires_time"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
                <div className="col-lg-3 mt-2">
                  <div className="Enter Price">
                    <input
                      name="expires_to"
                      type={
                        stockCategory.type_of_stocks === "Intraday"
                          ? "time"
                          : "date"
                      }
                      className="expires_time"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </div>
            </div>
            {stockCategory.type_of_stocks === "Options" && (
              <>
                <div className="me-2">
                  <div className="row">
                    {options.map((item, index) => {
                      return (
                        <>
                          <div className="col-lg-3 mt-2">
                            <div
                              className="exp_stock"
                              onClick={(e) => {
                                if (
                                  !(
                                    index === 1 &&
                                    stockCategory.category_of_stocks ===
                                      "Equity"
                                  )
                                ) {
                                  setisOptionOn(index);
                                }
                              }}
                              key={index}
                            >
                              <p
                                className={
                                  isOptionOn === index
                                    ? "exp_stock_para_active"
                                    : index === 1 &&
                                      stockCategory.category_of_stocks ===
                                        "Equity"
                                    ? "exp_stock_para_disabled"
                                    : "exp_stock_para"
                                }
                              >
                                {item}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    {opt_indicator.map((item, index) => {
                      return (
                        <>
                          <div className="col-lg-3 mt-2">
                            <div
                              className="exp_stock"
                              onClick={(e) => setOptionstype(index)}
                              key={index}
                            >
                              <p
                                className={
                                  optionstype === index
                                    ? "exp_stock_para_active"
                                    : "exp_stock_para"
                                }
                              >
                                {item}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <div className="me-3">
              <div className="row">
                {indicator.map((item, index) => {
                  return (
                    <>
                      <div className="col-lg-3 mt-2">
                        <div
                          className="exp_stock"
                          onClick={(e) => setbuyIndicator(index)}
                          key={index}
                        >
                          <p
                            className={
                              buyIndicator === index
                                ? "exp_stock_para_active"
                                : index === 1 &&
                                  stockCategory.category_of_stocks === "equity"
                                ? "exp_stock_para_disabled"
                                : "exp_stock_para"
                            }
                          >
                            {item}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="col-lg-3 mt-2">
                  <div className="">
                    <p className="only_price">Only Price@ </p>
                  </div>
                </div>
                <div className="col-lg-3 mt-2">
                  <div className="Enter Price">
                    <input
                      name="market"
                      type="number"
                      className="express_search"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="me-2 mt-2">
              <button className="buy_only_btn">Buy Only Price - ${10}</button>
            </div>
            <div className="me-2 mt-2">
              <select
                name="scenerio"
                id="scenerio"
                className="choose_scenario_dropdown"
              >
                <option value="volvo" disabled selected>
                  Choose Scenerio
                </option>
                <option>Option1</option>
                <option>Option1</option>
                <option>Option1</option>
                <option>Option1</option>
                <option>Option1</option>
              </select>
            </div>
            <div className="me-2 mt-3">
              <div className="row">
                <div className="col-lg-6">
                  <div className="target_level_div">
                    <h5 className="target_head">Target Level</h5>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      className="mt-2"
                    >
                      <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                      <p className="head-para2">
                        Target Levels are appropriate, user can exit the trade
                        wisely based on profit or loss.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">T1-Low</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">T2-Medium</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">T3-High</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="target_level_div">
                    <h5 className="target_head">Stop Loss Level</h5>
                    <div
                      style={{ display: "flex", flexDirection: "row" }}
                      className="mt-2"
                    >
                      <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                      <p className="head-para2">
                        Stoploss Levels are appropriate, user can exit the trade
                        wisely based on profit or loss.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">S1-Low</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">S2-Medium</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="target_indicator position-relative">
                        <p className="mb-0 target_indi_para">S3-High</p>
                        <div className="Enter Price mt-2">
                          <input
                            name="market"
                            type="number"
                            className="express_search"
                            placeholder="Enter Price"
                          />
                        </div>
                        <div className="indicators_div mt-2">
                          <p className="mb-0 indicator_points">pts</p>
                          <p className="inc_indicator">+</p>
                          <p className="dec_indicator">-</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 me-2">
              <h5 className="target_head">Chart</h5>
              <div className="row">
                <div className="col-lg-12">
                  <div className="chart_upload d-flex">
                    <div className="d-flex chart_div me-2">
                      <div>
                        <img src={docs_img} alt="" className="chart_img me-2" />
                      </div>
                      <form>
                        <input
                          type="file"
                          name="filename"
                          className="chart_input"
                        />
                      </form>
                    </div>
                    <div className="chart_upload_icon">
                      <i class="fa-solid fa-upload"></i>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="mt-2"
                  >
                    <i className="fa-solid fa-circle-info me-1 fa-circle-info3"></i>
                    <p className="head-para2" style={{ color: "#5E6871" }}>
                      Attached Refer Chart Showing Target & Stop Loss Level
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3 me-2">
              <div className="row">
                <div className="col-lg-6">
                  <div className="create_tip_card2 mt-2" onClick={setOpen}>
                    <p>Preview</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="create_tip_card3 mt-2">
                    <p>Submit</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content tip_previews_model">
          <div className="express_tip_preview">
            <div className="row">
              <div className="col-lg-12">
                <div className="express_preview_model">
                  <h5 className="express_head">Tip Preview:{"#12345678"}</h5>
                  <div className="d-flex justify-content-between pt-2">
                    <div className="d-flex">
                      <div>
                        <img
                          src={expert_img}
                          alt="profile_img"
                          className="expert_profile_tip me-2"
                        />
                      </div>
                      <div className="d-flex">
                        <div className="prof_right">
                          <p className="expert_id">#1234567</p>
                          <p className="expert_name">Kimberly</p>
                        </div>
                        <div className="d-flex expert_ratings">
                          <div className="rating_div d-flex me-2">
                            <i class="fa-solid fa-star my-auto ms-1"></i>
                            <p className="rating_number">5</p>
                          </div>

                          <div className="d-flex category_div">
                            <i class="fa-solid fa-star my-auto ms-1"></i>
                            <p className="rating_number">Bronze</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="preview_profile_right">
                      <div className="d-flex timer_div">
                        <i class="fa-solid fa-clock me-1"></i>
                        <p className="tips_timer mb-0">00:00:51</p>
                      </div>
                      <div className="ms-3 mt-1">
                        <p className="date_para">19/07/2024</p>
                        <p className="time_para">12:00:00</p>
                      </div>
                    </div>
                  </div>
                  <div className="div_card_parent mt-2">
                    <div className="stock_card_div d-flex justify-content-between">
                      <div className="stock_image d-flex">
                        <div>
                          <img
                            src={stock_img}
                            alt="../"
                            className="stock_img me-2"
                          />
                        </div>
                        <p className="stock_name_para">MSFT</p>
                      </div>
                      <div className="stock_live_update">
                        <p className="current_stock_price"> + $4,500.00</p>
                        <p className="live_indiator_para">+$538.46</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-8">
                        <p className="buy_price_para mb-0">
                          Buy MSFT Price - $8,650
                        </p>
                      </div>
                      <div className="col-lg-4">
                        <p className="buy_para mb-0">Buy</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mt-3">
                    <h6 className="scenerio_para">#Gap up</h6>
                  </div>
                  <div>
                    <div className="target_level_div">
                      <h5 className="target_head">Target Level</h5>
                      <div
                        style={{ display: "flex", flexDirection: "row" }}
                        className="mt-2"
                      >
                        <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                        <p className="head-para2">
                          Target Levels are appropriate, user can exit the trade
                          wisely based on profit or loss.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="target_indicator position-relative">
                          <p className="mb-0 target_indi_para">
                            T1-Low (pts:{10})
                          </p>
                          <div className="targets_amount mt-2">
                            <p className="mb-0">{"$8,650"}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="target_indicator position-relative">
                          <p className="mb-0 target_indi_para">
                            T2-Medium (pts:{10})
                          </p>
                          <div className="targets_amount mt-2">
                            <p className="mb-0">{"$8,650"}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="target_indicator position-relative">
                          <p className="mb-0 target_indi_para">
                            T3-High (pts:{10})
                          </p>
                          <div className="targets_amount mt-2">
                            <p className="mb-0">{"$8,650"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="target_level_div mt-3">
                      <h5 className="target_head">Stop Loss Level </h5>
                      <div
                        style={{ display: "flex", flexDirection: "row" }}
                        className="mt-2"
                      >
                        <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                        <p className="head-para2">
                          Stoploss Levels are appropriate, user can exit the
                          trade wisely based on profit or loss.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="target_indicator position-relative">
                          <p className="mb-0 target_indi_para">
                            S1-Low (pts:{10})
                          </p>
                          <div className="targets_amount mt-2">
                            <p className="mb-0">{"$8,650"}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="target_indicator position-relative">
                          <p className="mb-0 target_indi_para">
                            S2-Medium (pts:{10})
                          </p>
                          <div className="targets_amount mt-2">
                            <p className="mb-0">{"$8,650"}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="target_indicator position-relative">
                          <p className="mb-0 target_indi_para">
                            S3-High (pts:{10})
                          </p>
                          <div className="targets_amount mt-2">
                            <p className="mb-0">{"$8,650"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chart_div_tip_model"></div>
                  <button
                    className="tips_close-btn mt-3"
                    onClick={handleFilter}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Tips;
