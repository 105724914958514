import moment from "moment";

export const formattedDateTime = (dateTime) => moment(dateTime).format('MMMM Do YYYY, h:mm A');

export const sunEditorOptions = {
    buttonList: [
        ['undo', 'redo'],
        ['formatBlock'],
        ['bold', 'italic', 'underline', 'strike'],
        ['fontColor', 'hiliteColor'],
        ['align', 'list', 'indent', 'outdent'],
        ['table', 'link'],
        ['fullScreen', 'showBlocks', 'codeView'],
    ]
}