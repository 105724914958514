import React, { useState, useCallback, useEffect } from 'react';
import SunEditor from 'suneditor-react';
import { useSelector } from 'react-redux';
import 'suneditor/dist/css/suneditor.min.css';
import { getCms, updateCms } from '../../../common/Api';
import { successMsg } from '../../../containers/Toastify';
import { sunEditorOptions } from '../../../utils/Utils';
const TermsAndConditions = () => {
  const { token } = useSelector((state) => state.login);
  const [data, setData] = useState({});
  const [error, setError] = useState("");
  const getData = useCallback(async () => {
    const response = await getCms('expert_terms_and_condition', token);
    if (response && response?.statusCode === 200) {
      setData(response?.data);
    }
  }, [token]);

  const updateData = async () => {
    setError()
    const payload = {
      cms_document_type: "expert_terms_and_condition",
      content: data?.content
    }
    const response = await updateCms(payload, token)
    if (response && response?.statusCode === 200) {
      console.log(response)
      successMsg(response?.message)
      getData()
    }
  }

  useEffect(() => {
    getData();
  }, [getData]);

  const handleChange = (content) => {
    setData({
      ...data,
      content: content
    })
  };
  const regex = /(<([^>]+)>)/ig
  const hasText = data?.content && !!data?.content?.replace(regex, "").length
  const handleSubmit = () => {
    if (hasText) {
      updateData()
    } else {
      setError("* This Field should not be empty")
    }
  }
  console.log(data)
  return (
    <div className='admin-content-body'>
      <div className='admin-form-heading d-flex justify-content-between align-items-center'>
        <p className='mb-0'>Expert Terms And Conditions</p>
        <button className='admin-btn' onClick={handleSubmit}>Update</button>
      </div>
      <div className='Admin-form-content pt-0'>
        {error &&
          <small className='validation-error'>
            {error}
          </small>
        }
        {data?.content ?
          <SunEditor
            defaultValue={data?.content}
            placeholder='Please type something'
            onChange={handleChange}
            setOptions={sunEditorOptions}
          />
          : "loading...."}
      </div>
    </div>
  );
};

export default TermsAndConditions;
