import React, { useState } from "react";
import CustomModal from "../../../components/CustomModal";
import expert_img from "../../../assets/images/expert_profile_img.png";
import stock_img from "../../../assets/images/stock_img.png";

const ExpressTips = () => {
  const [open, setOpen] = useState(false);
  const handleFilter = () => {
    setOpen(!open);
  };
  const stocks = ["Intraday", "Scalping"];
  const indicator = ["Buy", "Sell"];
  const [clikedStock, setClickedStock] = useState();
  const [buyIndicator, setbuyIndicator] = useState();

  return (
    <div>
      <div className="expert_express_tips">
        <div className="me-2">
          <div className="row">
            <div className="col-lg-12">
              <input
                name="market"
                className="express_search"
                placeholder="Search Stock Name"
              />
            </div>
          </div>
        </div>
        <div className="me-2 mt-2">
          <div className="row">
            {stocks.map((item, index) => {
              return (
                <>
                  <div className="col-lg-3">
                    <div
                      className="exp_stock"
                      onClick={(e) => setClickedStock(index)}
                      key={index}
                    >
                      <p
                        className={
                          clikedStock === index
                            ? "exp_stock_para_active"
                            : "exp_stock_para"
                        }
                      >
                        {item}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="col-lg-3">
              <div className="exp_stock">
                <input
                  name="expires_from"
                  type="time"
                  className="expires_time"
                  placeholder="Enter Price"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="exp_stock">
                <input
                  name="expires_to"
                  type="time"
                  className="expires_time"
                  placeholder="Enter Price"
                />
              </div>
            </div>
            {indicator.map((item, index) => {
              return (
                <>
                  <div className="col-lg-3 mt-2">
                    <div
                      className="exp_stock"
                      onClick={(e) => setbuyIndicator(index)}
                      key={index}
                    >
                      <p
                        className={
                          buyIndicator === index
                            ? "exp_stock_para_active"
                            : "exp_stock_para"
                        }
                      >
                        {item}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="col-lg-3 mt-2">
              <div className="">
                <p className="only_price">Only Price@ </p>
              </div>
            </div>
            <div className="col-lg-3 mt-2">
              <div className="Enter Price">
                <input
                  name="market"
                  type="number"
                  className="express_search"
                  placeholder="Enter Price"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="me-2 mt-2">
          <button className="buy_only_btn">Buy Only Price - ${10}</button>
        </div>
        <div className="me-2 mt-2">
          <select
            name="scenerio"
            id="scenerio"
            className="choose_scenario_dropdown"
          >
            <option value="volvo" disabled selected>
              Choose Scenerio
            </option>
            <option value="saab">Saab</option>
            <option value="mercedes">Mercedes</option>
            <option value="audi">Audi</option>
          </select>
        </div>
        <div className="me-2 mt-2">
          <div className="row">
            <div className="col-lg-6">
              <div className="target_level_div">
                <h5 className="target_head">Target Level</h5>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className="mt-2"
                >
                  <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                  <p className="head-para2">
                    Target Levels are appropriate, user can exit the trade
                    wisely based on profit or loss.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="target_indicator position-relative">
                    <p className="mb-0 target_indi_para">T1-Low</p>
                    <div className="Enter Price mt-2">
                      <input
                        name="market"
                        type="number"
                        className="express_search"
                        placeholder="Enter Price"
                      />
                    </div>
                    <div className="indicators_div mt-2">
                      <p className="mb-0 indicator_points">pts</p>
                      <p className="inc_indicator">+</p>
                      <p className="dec_indicator">-</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="target_indicator position-relative">
                    <p className="mb-0 target_indi_para">T2-Medium</p>
                    <div className="Enter Price mt-2">
                      <input
                        name="market"
                        type="number"
                        className="express_search"
                        placeholder="Enter Price"
                      />
                    </div>
                    <div className="indicators_div mt-2">
                      <p className="mb-0 indicator_points">pts</p>
                      <p className="inc_indicator">+</p>
                      <p className="dec_indicator">-</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="target_indicator position-relative">
                    <p className="mb-0 target_indi_para">T3-High</p>
                    <div className="Enter Price mt-2">
                      <input
                        name="market"
                        type="number"
                        className="express_search"
                        placeholder="Enter Price"
                      />
                    </div>
                    <div className="indicators_div mt-2">
                      <p className="mb-0 indicator_points">pts</p>
                      <p className="inc_indicator">+</p>
                      <p className="dec_indicator">-</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="create_tip_card2 mt-2" onClick={setOpen}>
                <p>Preview</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="target_level_div">
                <h5 className="target_head">Stop Loss Level</h5>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className="mt-2"
                >
                  <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                  <p className="head-para2">
                    Stoploss Levels are appropriate, user can exit the trade
                    wisely based on profit or loss.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="target_indicator position-relative">
                    <p className="mb-0 target_indi_para">S1-Low</p>
                    <div className="Enter Price mt-2">
                      <input
                        name="market"
                        type="number"
                        className="express_search"
                        placeholder="Enter Price"
                      />
                    </div>
                    <div className="indicators_div mt-2">
                      <p className="mb-0 indicator_points">pts</p>
                      <p className="inc_indicator">+</p>
                      <p className="dec_indicator">-</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="target_indicator position-relative">
                    <p className="mb-0 target_indi_para">S2-Medium</p>
                    <div className="Enter Price mt-2">
                      <input
                        name="market"
                        type="number"
                        className="express_search"
                        placeholder="Enter Price"
                      />
                    </div>
                    <div className="indicators_div mt-2">
                      <p className="mb-0 indicator_points">pts</p>
                      <p className="inc_indicator">+</p>
                      <p className="dec_indicator">-</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="target_indicator position-relative">
                    <p className="mb-0 target_indi_para">S3-High</p>
                    <div className="Enter Price mt-2">
                      <input
                        name="market"
                        type="number"
                        className="express_search"
                        placeholder="Enter Price"
                      />
                    </div>
                    <div className="indicators_div mt-2">
                      <p className="mb-0 indicator_points">pts</p>
                      <p className="inc_indicator">+</p>
                      <p className="dec_indicator">-</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="create_tip_card3 mt-2">
                <p>Submit</p>
              </div>
            </div>
          </div>
        </div>

        <CustomModal
          open={open}
          onClickOutside={() => {
            setOpen(false);
          }}
        >
          <div className="modal-content tip_previews_model">
            <div className="express_tip_preview">
              <div className="row">
                <div className="col-lg-12">
                  <div className="express_preview_model">
                    <h5 className="express_head">Express Tip :{"#12345678"}</h5>
                    <div className="d-flex justify-content-between pt-2">
                      <div className="d-flex">
                        <div>
                          <img
                            src={expert_img}
                            alt="profile_img"
                            className="expert_profile_tip me-2"
                          />
                        </div>
                        <div className="d-flex">
                          <div className="prof_right">
                            <p className="expert_id">#1234567</p>
                            <p className="expert_name">Kimberly</p>
                          </div>
                          <div className="d-flex expert_ratings">
                            <div className="rating_div d-flex me-2">
                              <i class="fa-solid fa-star my-auto ms-1"></i>
                              <p className="rating_number">5</p>
                            </div>

                            <div className="d-flex category_div">
                              <i class="fa-solid fa-star my-auto ms-1"></i>
                              <p className="rating_number">Bronze</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="preview_profile_right">
                        <div className="d-flex timer_div">
                          <i class="fa-solid fa-clock me-1"></i>
                          <p className="tips_timer mb-0">00:00:51</p>
                        </div>
                        <div className="ms-3 mt-1">
                          <p className="date_para">19/07/2024</p>
                          <p className="time_para">12:00:00</p>
                        </div>
                      </div>
                    </div>
                    <div className="div_card_parent mt-2">
                      <div className="stock_card_div d-flex justify-content-between">
                        <div className="stock_image d-flex">
                          <div>
                            <img
                              src={stock_img}
                              alt="../"
                              className="stock_img me-2"
                            />
                          </div>
                          <p className="stock_name_para">MSFT</p>
                        </div>
                        <div className="stock_live_update">
                          <p className="current_stock_price"> + $4,500.00</p>
                          <p className="live_indiator_para">+$538.46</p>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-lg-8">
                          <p className="buy_price_para mb-0">
                            Buy MSFT Price - $8,650
                          </p>
                        </div>
                        <div className="col-lg-4">
                          <p className="buy_para mb-0">Buy</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex mt-3">
                      <h6 className="scenerio_para">#Gap up</h6>
                    </div>
                    <div>
                      <div className="target_level_div">
                        <h5 className="target_head">Target Level</h5>
                        <div
                          style={{ display: "flex", flexDirection: "row" }}
                          className="mt-2"
                        >
                          <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                          <p className="head-para2">
                            Target Levels are appropriate, user can exit the
                            trade wisely based on profit or loss.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="target_indicator position-relative">
                            <p className="mb-0 target_indi_para">
                              T1-Low (pts:{10})
                            </p>
                            <div className="targets_amount mt-2">
                              <p className="mb-0">{"$8,650"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="target_indicator position-relative">
                            <p className="mb-0 target_indi_para">
                              T2-Medium (pts:{10})
                            </p>
                            <div className="targets_amount mt-2">
                              <p className="mb-0">{"$8,650"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="target_indicator position-relative">
                            <p className="mb-0 target_indi_para">
                              T3-High (pts:{10})
                            </p>
                            <div className="targets_amount mt-2">
                              <p className="mb-0">{"$8,650"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="target_level_div mt-3">
                        <h5 className="target_head">Stop Loss Level </h5>
                        <div
                          style={{ display: "flex", flexDirection: "row" }}
                          className="mt-2"
                        >
                          <i className="fa-solid fa-circle-info me-1 fa-circle-info2"></i>
                          <p className="head-para2">
                            Stoploss Levels are appropriate, user can exit the
                            trade wisely based on profit or loss.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="target_indicator position-relative">
                            <p className="mb-0 target_indi_para">
                              S1-Low (pts:{10})
                            </p>
                            <div className="targets_amount mt-2">
                              <p className="mb-0">{"$8,650"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="target_indicator position-relative">
                            <p className="mb-0 target_indi_para">
                              S2-Medium (pts:{10})
                            </p>
                            <div className="targets_amount mt-2">
                              <p className="mb-0">{"$8,650"}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="target_indicator position-relative">
                            <p className="mb-0 target_indi_para">
                              S3-High (pts:{10})
                            </p>
                            <div className="targets_amount mt-2">
                              <p className="mb-0">{"$8,650"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="tips_close-btn mt-3"
                      onClick={handleFilter}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default ExpressTips;
