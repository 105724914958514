import React from "react";
import expert_profile from "../assets/images/expert_profile_img.png";
import bronze from "../assets/images/bronze_batch.png";
import silver from "../assets/images/silver_batch.png";
import gold from "../assets/images/gold_batch.png";

const Profile = () => {
  return (
    <div className="profile_parent_div">
      <div className="profile_top_section d-flex">
        <div className="prof_left">
          <img src={expert_profile} alt=".." />
        </div>
        <div className="prof_right">
          <p className="expert_id">#1234567</p>
          <p className="expert_name">Kimberly</p>
          <div className="d-flex">
            <div className="rating_div d-flex me-2">
              <i class="fa-solid fa-star my-auto ms-1"></i>
              <p className="rating_number">5</p>
            </div>

            <div className="d-flex category_div">
              <i class="fa-solid fa-star my-auto ms-1"></i>
              <p className="rating_number">Bronze</p>
            </div>
          </div>
        </div>
      </div>
      <div className="prof_bottom_div">
        <p className="folower_count">7.5k</p>
        <p className="total_text"> Total Followers</p>
      </div>
      <div className="expert_batch mt-3">
        <div className="expert_batch_div d-flex">
          <div>
            <img src={bronze} alt="" className="batch_img" />
          </div>
          <div>
            <p className="batch_text mb-0">Bronze</p>
            <p className="batch_followers mb-0">Follower- 0 to 10k</p>
          </div>
        </div>
        <div className="expert_batch_div d-flex mt-2">
          <div>
            <img src={silver} alt="" className="batch_img" />
          </div>
          <div>
            <p className="batch_text mb-0">Silver</p>
            <p className="batch_followers mb-0">Follower- 10K to 100k</p>
          </div>
        </div>
        <div className="expert_batch_div d-flex mt-2">
          <div>
            <img src={gold} alt="" className="batch_img" />
          </div>
          <div>
            <p className="batch_text mb-0">Gold</p>
            <p className="batch_followers mb-0">Follower- 100 & Above</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
