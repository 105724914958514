import React, { useState, useCallback, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import CustomModal from "../../../../components/CustomModal";
import { getFaqCategory, createFaqUserCategory, deleteFaqUserCategory, updateFaqUserCategory, getSpecificFaqUserCategory, createHelpnSupport, getHelpnSupport, getSpecificHelpnSupport, deleteHelpnSupport, updateHelpnSupport } from "../../../../common/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../../../containers/Toastify";
import { NonEmptyValidation } from "../../../../common/validate";
const ExpertQuery = () => {
    const { token } = useSelector((state) => state.login)
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [updateId, setUpdateId] = useState("")
    const [category, setCategory] = useState("")
    const [subject, setSubject] = useState([])
    const [error, setError] = useState("")
    const handleCreate = async () => {
        let validate = {}
        validate.subject = NonEmptyValidation(category.subject)
        validate.query = NonEmptyValidation(category.query)
        setError(validate)
        const checkInput = Object.values(validate).every((a) => a.status === true)
        if (checkInput) {
            const payload = {
                master_type: "query",
                master_user_type: "expert",
                value: category?.query,
                parent_id: category?.subject,
            }
            const response = await createHelpnSupport(payload, token)
            if (response && response?.statusCode === 200) {
                successMsg(response?.message)
                getData()
            }
            setOpenCreateModal(false)
            setError()
        }
    }

    const handleChange = (e, key) => {
        setCategory({
            ...category,
            [key]: e
        })
    }

    const getSubject = useCallback(async () => {
        const response = await getHelpnSupport("expert", "subject", token)
        if (response && response.statusCode === 200) {
            setSubject(response?.data?.help_and_support_master_setup)
        }
    }, [token])

    const getData = useCallback(async () => {
        const response = await getHelpnSupport("expert", "query", token)
        if (response && response?.statusCode === 200) {
            setData(response?.data?.help_and_support_master_setup)
        }
    }, [token])

    const getSpecificData = useCallback(async () => {
        const response = await getSpecificHelpnSupport(updateId, token)
        if (response && response?.statusCode === 200) {
            setCategory({
                subject: response?.data?.parent_id,
                query: response?.data?.value
            })
        }
    }, [updateId])

    const handleDelete = async () => {
        const payload = {
            _id: deleteId
        }
        const response = await deleteHelpnSupport(payload, token)
        if (response && response.statusCode === 200) {
            successMsg(response?.message)
            getData()
        }
    }

    const handlestatusUpdate = async (id, data, key) => {
        let stat
        if (data === "active") {
            stat = "inactive"
        } else if (data === "inactive") {
            stat = "active"
        }
        const payload = {
            _id: id,
            [key]: stat,
        };

        const response = await updateHelpnSupport(payload, token);
        if (response && response.statusCode === 200) {
            successMsg(response?.message);
            getData();
        } else {
            errorMsg(response?.message);
        }
    }

    const handleUpdate = async (id, data) => {
        let validate = {}
        validate.subject = NonEmptyValidation(category.subject)
        validate.query = NonEmptyValidation(category.query)
        setError(validate)
        const checkInput = Object.values(validate).every((a) => a.status === true)
        if (checkInput) {
            const payload = {
                _id: id,
                value: category?.query,
                parent_id: category?.subject,
            }
            const response = await updateHelpnSupport(payload, token)
            if (response && response?.statusCode === 200) {
                successMsg(response?.message)
                getData()
            }
            setOpenCreateModal(false)
            setError()
        }
    }

    useEffect(() => {
        getSpecificData()
    }, [updateId])

    useEffect(() => {
        getData()
        getSubject()
    }, [open, getData, getSubject])
    console.log(subject)
    const columns = [
        {
            name: "_id",
            label: "S.No",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return tableMeta.rowIndex + 1;
                },
            },
        },
        {
            name: "subject",
            label: "Subject",
        },
        {
            name: "value",
            label: "Query",
        },
        {
            name: "status",
            label: "Status",
        },
        {
            name: "",
            label: "Actions",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const status = tableMeta.rowData[3] === "active" ? true : false;
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <i
                                class="fa-solid fa-trash-arrow-up admin-delete-icon"
                                onClick={() => {
                                    setDeleteId(tableMeta.rowData[0])
                                    setOpen(true)
                                }}
                            ></i>
                            <label
                                className="switch"
                            >
                                <input
                                    type="checkbox"
                                    checked={status}
                                    onChange={() => {
                                        handlestatusUpdate(tableMeta.rowData[0], tableMeta.rowData[3], "status")
                                    }}
                                />
                                <span className="slider round"></span>
                            </label>
                            <i
                                class="fa-solid fa-user-pen admin-view-icon"
                                onClick={() => {
                                    setUpdateId(tableMeta.rowData[0])
                                    setOpenCreateModal(true)
                                }}
                            ></i>
                        </div>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
        selectableRows: "none",
    };

    return (
        <div className="">
            <div className="admin_btn_right">
                <button
                    className="admin-btn"
                    onClick={() => {
                        setOpenCreateModal(true)
                        setCategory()
                        setUpdateId()
                    }}
                >Create Query</button>
            </div>
            <MUIDataTable
                title={"Expert Help and Support Query List"}
                data={data}
                columns={columns}
                options={options}
            />
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content logout-modal p-5">
                    <p className="text-center mb-5 text-white">
                        Are you sure do you want to delete ?
                    </p>
                    <div className="row">
                        <div className="col-6">
                            <button className="btn ok-btn" onClick={() => {
                                handleDelete()
                                setOpen(false)
                            }}>Yes</button>
                        </div>
                        <div className="col-6">
                            <button className="btn cancel-btn text-green" onClick={() => setOpen(false)}>No</button>
                        </div>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                open={openCreateModal}
                onClickOutside={() => {
                    setOpenCreateModal(false);
                }}
            >
                <div className="modal-content logout-modal p-5">
                    <div className="mb-4">
                        <label className="text-white">Subject Name</label><br />
                        <select
                            className="form-control"
                            onChange={(e) => handleChange(e.target.value, "subject")}
                            value={category?.subject}
                        >
                            <option>Choose Subject</option>
                            {subject?.map((item, i) => (
                                <option value={item?._id}>{item?.value}</option>
                            ))}
                        </select>
                        {error?.subject?.message ?
                            <small className='validation-error'>
                                {error?.subject?.message}
                            </small> : ""
                        }
                    </div>
                    <div className="mb-4">
                        <label className="text-white">Query Name</label><br />
                        <input
                            type="text"
                            onChange={(e) => handleChange(e.target.value, "query")}
                            className="form-control"
                            value={category?.query}
                        />
                        {error?.query?.message ?
                            <small className='validation-error'>
                                {error?.query?.message}
                            </small> : ""
                        }
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <button
                                className="btn ok-btn"
                                onClick={() => {
                                    {
                                        updateId ?
                                            handleUpdate(updateId)
                                            : handleCreate()
                                    }
                                }}
                            >
                                {updateId ? "Update" : "Create"}
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                className="btn cancel-btn text-green"
                                onClick={() => setOpenCreateModal(false)}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ExpertQuery;
