import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";


const IndividualUserDetails = () => {
  const Tabs = [
    "User Data",
    "Tips Purchased",
    "transaction History",
    "Tip Rating",
  ];

  const secondaryTabs = ["Pending Ratings", "Received Rewards", "Spent Rewards"]
  const user_data_columns = [
    {
      name: "S.No",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "NAME",
    },
    {
      name: "profile_name",
      label: "PROFILE NAME",
    },
    {
      name: "phone_number",
      label: "PHONE NUMBER",
    },
    {
      name: "email_address",
      label: "EMAIL",
    },
    {
      name: "referral_code",
      label: "REFERRAL CODE",
    },
    {
      name: "status",
      label: "STATUS",
    },
  ];
  const user_data = [
    {
      name: "kishore",
      profile_name: "ksih_01",
      email_address: "kishore@gmail.com",
      phone_number: 8489519449,
      status: "active",
      referral_code: "ref124f",
    },
    {
      name: "kishore",
      profile_name: "ksih_01",
      email_address: "kishore@gmail.com",
      phone_number: 8489519449,
      status: "active",
      referral_code: "ref124f",
    },
    {
      name: "kishore",
      profile_name: "ksih_01",
      email_address: "kishore@gmail.com",
      phone_number: 8489519449,
      status: "active",
      referral_code: "ref124f",
    },
    {
      name: "kishore",
      profile_name: "ksih_01",
      email_address: "kishore@gmail.com",
      phone_number: 8489519449,
      status: "active",
      referral_code: "ref124f",
    },
    {
      name: "kishore",
      profile_name: "ksih_01",
      email_address: "kishore@gmail.com",
      phone_number: 8489519449,
      status: "active",
      referral_code: "ref124f",
    },
    {
      name: "kishore",
      profile_name: "ksih_01",
      email_address: "kishore@gmail.com",
      phone_number: 8489519449,
      status: "active",
      referral_code: "ref124f",
    },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };
  const user_TipsPurchased_columns = [
    {
      name: "tip_id",
      label: "TIP ID",
    },
    {
      name: "name",
      label: "uSER NAME",
    },
    {
      name: "stock_name",
      label: "STOCK NAME",
    },
    {
      name: "stock_id",
      label: "STOCK ID",
    },
    {
      name: "amount",
      label: "AMOUNT",
    },
    {
      name: "date",
      label: "DATE",
    },
    {
      name: "time",
      label: "TIME",
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <i class="fa-solid fa-eye admin-view-icon"></i>
            </div>
          );
        },
      },
    },
  ];
  const user_TipsPurchased_data = [
    {
      name: "kishore",
      date: "ksih_01",
      amount: "kishore@gmail.com",
      stock_id: 8489519449,
      stock_name: "active",
      tip_id: "ref124f",
      time: "ref124f",
    },
  ];
  const user_transaction_columns = [
    {
      name: "S.No",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "date",
      label: "DATE",
    },
    {
      name: "tip_id",
      label: "TIP ID",
    },
    {
      name: "user_id",
      label: "USER ID",
    },
    {
      name: "expert_id",
      label: "EXPERT ID",
    },
    {
      name: "amount",
      label: "AMOUNT",
    },
    {
      name: "transaction_id",
      label: "TRANSACTION ID",
    },
    {
      name: "pay_method",
      label: "PAY METHOD",
    },
  ];
  const user_transaction_data = [
    {
      pay_method: "kishore",
      transaction_id: "ksih_01",
      amount: "kishore@gmail.com",
      expert_id: 8489519449,
      user_id: "active",
      tip_id: "ref124f",
      time: "ref124f",
      date: "ref124f",
    },
  ];
  const user_tiprating_columns = [
    {
      name: "S.No",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "tip_id",
      label: "TIP ID",
    },
    {
      name: "tip_purchased_date",
      label: "TIP PURCHASED DATE",
    },
    {
      name: "tip_purchased_time",
      label: "TIP PURCHASED TIME",
    },
    {
      name: "tip_status",
      label: "TIP STATUS",
    },
  ];
  const user_tiprating_data = [
    {
      tip_purchased_date: "kishore",
      tip_purchased_time: "ksih_01",
      tip_status: "kishore@gmail.com",
      tip_id: "ref124f",
    },
  ];
  const received_rewards_columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "tip_id",
      label: "TIP ID",
    },
    {
      name: "tip_purchased_date",
      label: "TIP DATE",
    },
    {
      name: "tip_purchased_time",
      label: "TIP TIME",
    },
    {
      name: "_id",
      label: 'Actions',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <i class="fa-solid fa-eye admin-view-icon"></i>
            </div>
          );
        },
      }
    }
  ];
  const spent_rewards_columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "tip_id",
      label: "TIP ID",
    },
    {
      name: "tip_purchased_date",
      label: "TIP Purchased DATE",
    },
    {
      name: "tip_purchased_time",
      label: "TIP Purchased TIME",
    },
    {
      name: "tip_purchased_time",
      label: "TIP POINTS",
    }
  ];

  const [currentTab, setCurretTab] = useState(0);
  const [secondaryTab, setSecondaryTab] = useState(0)
  const navigate = useNavigate();
  const handleCurrentTab = (index) => {
    setCurretTab(index);
  };
  let data, columns;
  if (currentTab === 0) {
    data = user_data;
    columns = user_data_columns;
  } else if (currentTab === 1) {
    data = user_TipsPurchased_data;
    columns = user_TipsPurchased_columns;
  } else if (currentTab === 2) {
    data = user_transaction_data;
    columns = user_transaction_columns;
  } else if (currentTab === 3 && secondaryTab === 0) {
    data = user_tiprating_data;
    columns = user_tiprating_columns;
  } else if (currentTab === 3 && secondaryTab === 1) {
    data = user_tiprating_data;
    columns = received_rewards_columns;
  }
  else if (currentTab === 3 && secondaryTab === 2) {
    data = user_tiprating_data;
    columns = spent_rewards_columns;
  }

  return (
    <div>
      <div className="individual_users_parent_div">
        <div className="container">
          <div className="d-flex">
            {Tabs.map((item, index) => {
              return (
                <p
                  className={
                    currentTab === index ? "tab_head_active" : "tab_head"
                  }
                  key={index}
                  onClick={() => handleCurrentTab(index)}
                >
                  {item}
                </p>
              );
            })}
          </div>
          {currentTab === 3 &&
            <div className="d-flex justify-content-center mt-5">
              {secondaryTabs.map((item, index) => {
                return (
                  <p
                    className={
                      secondaryTab === index ? "tab_head_active" : "tab_head"
                    }
                    key={index}
                    onClick={() => setSecondaryTab(index)}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          }
        </div>
      </div>
      <div className="">
        <MUIDataTable data={data} columns={columns} options={options} />
      </div>
    </div>
  );
};

export default IndividualUserDetails;
