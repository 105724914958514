import React, { useState, useCallback, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import CustomModal from "../../../components/CustomModal";
import { getFaqCategory, createFaqUserCategory, deleteFaqUserCategory, updateFaqUserCategory, getSpecificFaqUserCategory } from "../../../common/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../../containers/Toastify";
const FaqCategory = () => {
    const { token } = useSelector((state) => state.login)
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [deleteId, setDeleteId] = useState("")
    const [updateId, setUpdateId] = useState("")
    const [category, setCategory] = useState("")
    const [error, setError] = useState("")
    const handleCreate = async () => {
        if (!!category) {
            const payload = {
                category_type: "user",
                category: category
            }
            const response = await createFaqUserCategory(payload, token)
            if (response && response?.statusCode === 200) {
                successMsg(response?.message)
                getData()
            }
            setOpenCreateModal(false)
            setError()
        } else (
            setError("This Field Should not be empty")
        )
    }

    const getData = useCallback(async () => {
        const response = await getFaqCategory("user",token)
        if (response && response?.statusCode === 200) {
            setData(response?.data?.faq_category_datas)
        }
    }, [token])

    const getSpecificData = useCallback(async () => {
        const response = await getSpecificFaqUserCategory(updateId, token)
        if (response && response?.statusCode === 200) {
            setCategory(response?.data?.category)
        }
    }, [updateId])

    const handleDelete = async () => {
        const payload = {
            _id: deleteId
        }
        const response = await deleteFaqUserCategory(payload, token)
        if (response && response.statusCode === 200) {
            successMsg(response?.message)
            getData()
        }
    }

    const handleUpdate = async (id, data, key) => {
        let stat
        if (data === "active") {
            stat = "inactive"
        } else if (data === "inactive") {
            stat = "active"
        } else {
            stat = data
        }
        if (key === "category" && !category) {
            setError("This Field Should not be empty");
            return;
        }
        setError()
        setOpenCreateModal(false)
        const payload = {
            _id: id,
            category_type: "user",
            [key]: stat,
        };

        const response = await updateFaqUserCategory(payload, token);
        if (response && response.statusCode === 200) {
            successMsg(response?.message);
            getData();
        } else {
            errorMsg(response?.message);
        }
    }

    useEffect(() => {
        getSpecificData()
    }, [updateId])

    useEffect(() => {
        getData()
    }, [open])

    const columns = [
        {
            name: "_id",
            label: "S.No",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return tableMeta.rowIndex + 1;
                },
            },
        },
        {
            name: "category",
            label: "Category",
        },
        {
            name: "status",
            label: "Status",
        },
        {
            name: "",
            label: "Actions",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const status = tableMeta.rowData[2] === "active" ? true : false;
                    return (
                        <div className="d-flex align-items-center justify-content-center">
                            <i
                                class="fa-solid fa-trash-arrow-up admin-delete-icon"
                                onClick={() => {
                                    setDeleteId(tableMeta.rowData[0])
                                    setOpen(true)
                                }}
                            ></i>
                            <label
                                className="switch"
                            >
                                <input
                                    type="checkbox"
                                    checked={status}
                                    onChange={() => {
                                        handleUpdate(tableMeta.rowData[0], tableMeta.rowData[2], "status")
                                    }}

                                />
                                <span className="slider round"></span>
                            </label>
                            <i
                                class="fa-solid fa-user-pen admin-view-icon"
                                onClick={() => {
                                    setUpdateId(tableMeta.rowData[0])
                                    setOpenCreateModal(true)
                                }}
                            ></i>
                        </div>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
        selectableRows: "none",
    };

    return (
        <div className="">
            <div className="admin_btn_right">
                <button
                    className="admin-btn"
                    onClick={() => {
                        setOpenCreateModal(true)
                        setCategory()
                        setUpdateId()
                    }}
                >Create Category</button>
            </div>
            <MUIDataTable
                title={"User Faq Category List"}
                data={data}
                columns={columns}
                options={options}
            />
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content logout-modal p-5">
                    <p className="text-center mb-5 text-white">
                        Are you sure do you want to delete ?
                    </p>
                    <div className="row">
                        <div className="col-6">
                            <button className="btn ok-btn" onClick={() => {
                                handleDelete()
                                setOpen(false)
                            }}>Yes</button>
                        </div>
                        <div className="col-6">
                            <button className="btn cancel-btn text-green" onClick={() => setOpen(false)}>No</button>
                        </div>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                open={openCreateModal}
                onClickOutside={() => {
                    setOpenCreateModal(false);
                }}
            >
                <div className="modal-content logout-modal p-5">
                    <div className="mb-4">
                        <label className="text-white">Category Name</label><br />
                        <input
                            type="text"
                            onChange={(e) => setCategory(e.target.value)}
                            className="form-control"
                            value={category}
                        />
                        {error ?
                            <small className='validation-error'>
                                {error}
                            </small> : ""
                        }
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <button
                                className="btn ok-btn"
                                onClick={() => {
                                    {
                                        updateId ?
                                            handleUpdate(updateId, category, "category")
                                            : handleCreate()
                                    }
                                }}
                            >
                                {updateId ? "Update" : "Create"}
                            </button>
                        </div>
                        <div className="col-6">
                            <button
                                className="btn cancel-btn text-green"
                                onClick={() => setOpenCreateModal(false)}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default FaqCategory;
