import React, { useEffect, useRef, useState } from "react";
import Logo from '../../assets/images/logo-white.png'
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminVerifyOtp, AdminForgotPassword } from "../../common/Api";
import { successMsg, errorMsg } from "../../containers/Toastify";
const VerifyOtp = () => {
    const { emailId } = useSelector((state) => state?.login);
    const navigate = useNavigate();
    const Ref = useRef(null);

    const [otpNum, setOtpNum] = useState("");
    const [timer, setTimer] = useState("00:00");
    const [error, setError] = useState("")
    const [validation, setValidation] = useState()
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);

        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const clearTimer = (e) => {
        setTimer("00:05");
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 5);
        return deadline;
    };

    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);

    const onClickReset = () => {
        handleSubmitResend();
        clearTimer(getDeadTime());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (otpNum === "") {
            setError("Please provide otp number")
        } else {
            setError("")
        }
        const Payload = {
            email_id: emailId,
            otp: otpNum,
            otp_type: "forgot_password",
        };
        const response = await AdminVerifyOtp(Payload);
        if (response && response.statusCode === 200) {
            successMsg(response.message);
            navigate("/change-password")
        } else {
            errorMsg(response.message);
        }
    };

    const handleOtp = (num) => {
        setOtpNum(num);
    };


    const handleSubmitResend = async () => {
        const Payload = {
            email_id: emailId,
        };
        const response = await AdminForgotPassword(Payload);
        if (response && response.statusCode === 200) {
            successMsg(response.message);
        } else {
            errorMsg(response.message);
        }
    };
    return (
        <div className='login-bg justify-content-center'>
            <div className='forgot-password-screen verify-otp-screen'>
                <img className='fp-logo' src={Logo} alt='guy-with-laptop' />
                <h5 className='text-white text-center mb-5'>Verify With OTP</h5>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="otpSpace">
                        <OtpInput
                            className=""
                            value={otpNum}
                            onChange={handleOtp}
                            numInputs={4}
                            renderInput={(props) => <input {...props} />}
                            renderSeparator={<span className="justify-content-between"></span>}
                            inputStyle={{
                                border: "1px solid #CFD3DB",
                                borderRadius: "8px",
                                width: "45px",
                                height: "45px",
                                color: "#000",
                                fontWeight: "400",
                                caretColor: "blue",
                                fontSize: "18px"
                            }}
                            focusStyle={{
                                border: "1px solid #3773C9",
                                outline: "none",
                            }}
                            separator={<span style={{ width: "8px" }}></span>}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            name="otpValue"
                        />
                    </div>
                    {error ?
                        <small className='text-center validation-error'>
                            {error}
                        </small> : ""
                    }
                    <div className="mt-5">
                        <button className="btn login-btn w-100">Verify OTP</button>
                    </div>

                    <div className="displayflexspace">
                        <p
                            className="text-end"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <small className="text-white pointer">Back</small>
                        </p>
                        <button
                            className="text-start resendBtn"
                            onClick={onClickReset}
                            disabled={timer !== "00:00"}
                        >
                            <small className="text-white">Resend OTP {timer === "00:00" ? "" : `in ${timer}`}</small>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default VerifyOtp