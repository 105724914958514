import React from 'react'
import Login from '../../features/user/Login'

const ExternalPage = () => {
    return (
        <div>
            <Login />
        </div>
    )
}

export default ExternalPage