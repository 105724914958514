import AdminNotFound from "../features/protected/AdminNotFound";
import AppConfiguartion from "../features/protected/AppConfiguartion";
import EnquiryForm from "../features/protected/web/EnquiryForm";
import UserDetails from "../features/protected/UserDetail/UserDetails";
import AdminManagement from "../features/protected/AdminManagement/AdminList";
import CreateAdmin from "../features/protected/AdminManagement/CreateAdmin";
import EditAdmin from "../features/protected/AdminManagement/EditAdmin";
import IndividualUserDetails from "../features/protected/UserDetail/IndividualUserDetails";
import Category from "../features/protected/Filter/Category";
import Subcategory from "../features/protected/Filter/Subcategory";
import UserPrivacyPolicy from "../features/protected/user/PrivacyPolicy";
import UserTermsAndConditions from "../features/protected/user/TermsAndConditions";
import ExpertPrivacyPolicy from "../features/protected/Expert-admin/PrivacyPolicy";
import ExpertTermsAndConditions from "../features/protected/Expert-admin/TermsAndConditions";
import UserFaqCategory from "../features/protected/user/FaqCategory";
import ExpertFaqCategory from "../features/protected/Expert-admin/FaqCategory";
import ChangePassword from "../features/protected/ChangePassword";
import UserFaqList from "../features/protected/user/FAQ/FaqList";
import ExpertFaqList from "../features/protected/Expert-admin/FAQ/FaqList";
import CreateFaq from "../features/protected/user/FAQ/CreateFaq";
import ExpertCreateFaq from "../features/protected/Expert-admin/FAQ/CreateFaq";
import Dashboard from "../features/protected/Expert/Dashboard";
import ExpressTips from "../features/protected/Expert/ExpressTips";
import Tips from "../features/protected/Expert/Tips";
import UpdateUserFaq from "../features/protected/user/FAQ/UpdateFaq";
import UpdateExpertFaq from "../features/protected/Expert-admin/FAQ/UpdateFaq";
import ProfileUpdate from "../features/protected/ProfileUpdate";
import UserSubject from "../features/protected/user/HelpAndSupport/UserSubject";
import UserQuery from "../features/protected/user/HelpAndSupport/UserQuery";
import ExpertSubject from "../features/protected/Expert-admin/HelpAndSupport/ExpertSubject";
import ExpertQuery from "../features/protected/Expert-admin/HelpAndSupport/ExpertQuery";
export const routes = [
  {
    path: "/userDetails",
    component: UserDetails,
  },
  {
    path: "/userIndividualDetails",
    component: IndividualUserDetails,
  },
  {
    path: "/appConfiguration",
    component: AppConfiguartion,
  },
  {
    path: "/userEnquiries",
    component: EnquiryForm,
  },
  {
    path: "/adminManagement",
    component: AdminManagement,
  },
  {
    path: "*",
    component: AdminNotFound,
  },
  {
    path: "/createAdmin",
    component: CreateAdmin,
  },
  {
    path: "/EditAdmin/:id",
    component: EditAdmin,
  },
  {
    path: "/filter/category",
    component: Category,
  },
  {
    path: "/filter/subCategory",
    component: Subcategory,
  },
  {
    path: "expert/filter/category",
    component: Category,
  },
  {
    path: "expert/filter/subCategory",
    component: Subcategory,
  },
  {
    path: "/user/privacyPolicy",
    component: UserPrivacyPolicy,
  },
  {
    path: "/user/termsAndCondition",
    component: UserTermsAndConditions,
  },
  {
    path: "/expert/privacyPolicy",
    component: ExpertPrivacyPolicy,
  },
  {
    path: "/expert/termsAndCondition",
    component: ExpertTermsAndConditions,
  },
  {
    path: "/faq/category",
    component: UserFaqCategory,
  },
  {
    path: "/expert/faq/category",
    component: ExpertFaqCategory,
  },
  {
    path: "/changePassword",
    component: ChangePassword
  },
  {
    path: "/faq/questionAnswer",
    component: UserFaqList
  },
  {
    path: "/faq/create",
    component: CreateFaq
  },
  {
    path: "/app/dashboard",
    component: Dashboard,
  },
  {
    path: "/expressTips",
    component: ExpressTips,
  },
  {
    path: "/tips",
    component: Tips,
  },
  {
    path: "/editFaq/:id",
    component: UpdateUserFaq,
  },
  {
    path: "/expert/faq/questionAnswer",
    component: ExpertFaqList
  },
  {
    path: "/expert/faq/create",
    component: ExpertCreateFaq
  },
  {
    path: "/expert/editFaq/:id",
    component: UpdateExpertFaq,
  },
  {
    path: "/profileUpdate",
    component: ProfileUpdate,
  },
  {
    path: "/helpAndSupport/subject",
    component: UserSubject,
  },
  {
    path: "/helpAndSupport/query",
    component: UserQuery,
  },
  {
    path: "/expert/helpAndSupport/subject",
    component: ExpertSubject,
  },
  {
    path: "/expert/helpAndSupport/query",
    component: ExpertQuery,
  },
];
