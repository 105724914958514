import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getFaqCategory, getSpecificFaq, updateFaq } from '../../../../common/Api'
import { useSelector } from 'react-redux'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { NonEmptyValidation, textEditorValidation } from '../../../../common/validate';
import { errorMsg, successMsg } from '../../../../containers/Toastify';
import { sunEditorOptions } from '../../../../utils/Utils';

const UpdateFaq = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { token } = useSelector((state) => state.login)
  const [category, setCategory] = useState([])
  const [data, setData] = useState({})
  console.log(data)
  const [validation, setValidation] = useState({})
  const handleChange = (e, key) => {
    setData((prev) => ({
      ...prev,
      [key]: e
    }))
  }
  const getSpecificData = useCallback(async () => {
    const response = await getSpecificFaq(id, token)
    if (response && response?.statusCode === 200) {
      console.log(response?.data)
      setData({
        category: response?.data?.category_id,
        question: response?.data?.question,
        answer: response?.data?.answer
      })
    }
  }, [token])


  const getCategory = useCallback(async () => {
    const response = await getFaqCategory("user", token)
    if (response && response?.statusCode === 200) {
      setCategory(response?.data?.faq_category_datas)
    }
  }, [token])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let validate = {}
    validate.category = NonEmptyValidation(data?.category)
    validate.question = NonEmptyValidation(data?.question)
    validate.answer = textEditorValidation(data?.answer)
    setValidation(validate)
    const checkInput = Object.values(validate).every((val) => val.status === true)
    if (checkInput) {
      const payload = {
        _id: id,
        category_id: data?.category,
        question: data?.question,
        answer: data?.answer
      }
      const response = await updateFaq(payload, token)
      if (response && response.statusCode === 200) {
        successMsg(response?.message)
        navigate(-1)
      } else {
        errorMsg(response?.message)
      }
    } else {
      errorMsg("Check all the input fields")
    }
  }
  useEffect(() => {
    getCategory()
    getSpecificData()
  }, [getCategory, getSpecificData])
  return (
    <div className='admin-content-body'>
      <div className='admin-form-heading d-flex justify-content-between align-items-center'>
        <p className='mb-0'>
          <i class="fa-solid fa-arrow-left me-3 pointer" onClick={() => navigate(-1)}></i>
          Update Faq
        </p>
        <button className='admin-btn' onClick={handleSubmit}>Update</button>
      </div>
      <div className='Admin-form-content'>
        <div className='d-flex mb-4'>
          <div className='w-25'>
            <label className='admin-panel-label'>Category</label><br />
            <select
              className='form-control admin-panel-input'
              onChange={(e) => handleChange(e.target.value, "category")}
              value={data?.category}
            >
              <option value="">Select Category</option>
              {category?.map((item, i) => (
                <option key={i} value={item?._id}>{item?.category}</option>
              )
              )}
            </select>
            {validation?.category?.message ?
              <small className='validation-error'>
                {`Category ${validation?.category?.message}`}
              </small> : ""
            }
          </div>
          <div className='w-75 ps-4'>
            <label className='admin-panel-label '>Question</label><br />
            <input
              className='form-control admin-panel-input'
              onChange={(e) => handleChange(e.target.value, "question")}
              value={data?.question}
            />
            {validation?.question?.message ?
              <small className='validation-error'>
                {`Question ${validation?.question?.message}`}
              </small> : ""
            }
          </div>
        </div>
        {data?.answer ?
          <SunEditor
            placeholder='Please type something'
            onChange={(e) => handleChange(e, "answer")}
            defaultValue={data?.answer}
            setOptions={sunEditorOptions}
          /> : ""}
        {validation?.answer?.message ?
          <small className='validation-error'>
            {`Answer ${validation?.answer?.message}`}
          </small> : ""
        }
      </div>
    </div>
  )
}

export default UpdateFaq