import React, { useState } from 'react'
import Logo from '../../assets/images/logo-white.png'
import { EmailValidation } from '../../common/validate'
import { AdminForgotPassword } from '../../common/Api'
import { errorMsg, successMsg } from '../../containers/Toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { registerData } from '../../store/slice/loginSlice'
const ForgotPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState()
  const [validation, setValidation] = useState({})
  const handleSubmit = async (e) => {
    e.preventDefault()
    const validate = EmailValidation(email, "email")
    setValidation(validate)
    if (validate?.status === true) {
      const payload = {
        email_id: email
      }
      const response = await AdminForgotPassword(payload)
      if (response && response?.statusCode === 200) {
        dispatch(registerData(email))
        successMsg(response?.message)
        navigate('/verifyOtp')
      } else {
        errorMsg(response?.message)
      }
    }
  }

  return (
    <div className='login-bg justify-content-center'>
      <div className='forgot-password-screen'>
        <img className='fp-logo' src={Logo} alt='guy-with-laptop' />
        <h4 className='mb-3 text-white'>Forgot Password?</h4>
        <p className='fb-text'>Welcome Back!!! Plesae Enter Your Email Address</p>
        <div className=''>
          <input
            placeholder='Enter your email address'
            type='email'
            className='form-control'
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {validation?.message ?
          <small className='validation-error'>
            {validation?.message}
          </small>
          : ""}
        <button className='btn login-btn mt-4' onClick={handleSubmit}>Get Otp</button>
        <p
          className="text-end forgettext"
          onClick={() => {
            navigate(-1);
          }}
        >
          <small className="text-white pointer">Back</small>
        </p>
      </div>
    </div>
  )
}

export default ForgotPassword 