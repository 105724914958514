import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmailValidation, NonEmptyValidation, PasswordValidation, PhoneNumberValidation } from '../../../common/validate'
import { createAdmin } from "../../../common/Api";
import { useSelector } from "react-redux";
import { successMsg, errorMsg } from '../../../containers/Toastify';

const CreateAdmin = () => {
  const { token } = useSelector((state) => state?.login)
  const navigate = useNavigate()
  const [userData, setUserData] = useState({})
  const [validation, setValidation] = useState({})
  const [viewPassword, setViewPassword] = useState({})
  console.log(validation)
  console.log(userData)
  const handleChange = (e, key) => {
    setUserData({
      ...userData,
      [key]: e
    })
  }
  const handleEyeClick = (key, value) => {
    setViewPassword({
      ...viewPassword,
      [key]: value
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    let validate = {}
    validate.name = NonEmptyValidation(userData?.name)
    validate.phoneNumber = PhoneNumberValidation(userData?.phoneNumber)
    validate.email = EmailValidation(userData?.email)
    validate.password = PasswordValidation(userData?.password)
    validate.confirm_password = PasswordValidation(userData?.confirm_password)
    setValidation(validate)
    const checkInput = Object.values(validate).every((a) => a.status === true)
    if (checkInput) {
      if (userData?.password === userData?.confirm_password) {
        const payload = {
          name: userData?.name,
          email_id: userData?.email,
          phone_number: userData?.phoneNumber,
          password: userData?.password
        }
        const response = await createAdmin(payload, token)
        if (response && response.statusCode === 200) {
          successMsg(response?.message)
          navigate(-1)
        } else {
          errorMsg(response?.message)
        }
      } else {
        errorMsg("Passwords should be same")
      }
    }
  }

  return (
    <div className='admin-content-body'>
      <div className='admin-form-heading d-flex justify-content-between align-items-center'>
        <p className='mb-0'>
          <i class="fa-solid fa-arrow-left me-3 pointer" onClick={() => navigate(-1)}></i>
          Create Admin
        </p>
        <button className='admin-btn' onClick={handleSubmit}>Create</button>
      </div>
      <div className='Admin-form-content'>
        <div className="row">
          <div className="col-lg-6">
            <div className="admin_input mb-3">
              <label>
                Name<span className="ms-1">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter Name"
                defaultValue={userData?.name}
                onChange={(e) => handleChange(e.target.value, "name")}
              />
              {validation?.name?.message ?
                <small className='validation-error'>
                  {`Name ${validation?.name?.message}`}
                </small> : ""
              }
            </div>
          </div>
          <div className="col-lg-6">
            <div className="admin_input">
              <label>
                Phone Number<span className="ms-1">*</span>
              </label>
              <input
                type="tel"
                name="phoneNumber"
                placeholder="Enter Phone Number"
                defaultValue={userData?.phoneNumber}
                onChange={(e) => handleChange(e.target.value, "phoneNumber")}
              />
              {validation?.phoneNumber?.message ?
                <small className='validation-error'>
                  {`phone Number ${validation?.phoneNumber?.message}`}
                </small> : ""
              }
            </div>
          </div>
          <div className="col-lg-6 mb-3">
            <div className="admin_input">
              <label>
                Email<span className="ms-1">*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter Email"
                autoComplete="off"
                defaultValue={userData?.email}
                onChange={(e) => handleChange(e.target.value, "email")}
              />
              {validation?.email?.message ?
                <small className='validation-error'>
                  {`Email ${validation?.email?.message}`}
                </small> : ""
              }
            </div>
          </div>
          <div className="col-lg-6">
            <div className="admin_input position-relative" >
              <label>
                Password<span className="ms-1">*</span>
              </label>
              <input
                type={!viewPassword?.enteredPassword ? "password" : "text"}
                autoComplete="off"
                name="password"
                placeholder="Enter Password"
                defaultValue={userData?.password}
                onChange={(e) => handleChange(e.target.value, "password")}
              />
              {
                viewPassword?.enteredPassword ?
                  <i
                    className="fa-solid fa-eye change-password-eye  top-58"
                    onClick={() => handleEyeClick("enteredPassword", false)}
                  ></i> :
                  <i className="fa-solid fa-eye-slash change-password-eye  top-58"
                    onClick={() => handleEyeClick("enteredPassword", true)}
                  ></i>
              }
              {validation?.password?.message ?
                <small className='validation-error'>
                  {`Email ${validation?.password?.message}`}
                </small> : ""
              }
            </div>
          </div>
          <div className="col-lg-6">
            <div className="admin_input position-relative">
              <label>
                Confirm Password<span className="ms-1">*</span>
              </label>
              <input
                type={!viewPassword?.ReenteredPassword ? "password" : "text"}
                name="confirm_password"
                placeholder="Confirm Password"
                defaultValue={userData?.confirm_password}
                onChange={(e) => handleChange(e.target.value, "confirm_password")}
              />
              {
                viewPassword?.ReenteredPassword ?
                  <i
                    className="fa-solid fa-eye change-password-eye  top-58"
                    onClick={() => handleEyeClick("ReenteredPassword", false)}
                  ></i> :
                  <i className="fa-solid fa-eye-slash change-password-eye  top-58"
                    onClick={() => handleEyeClick("ReenteredPassword", true)}
                  ></i>
              }
              {validation?.confirm_password?.message ?
                <small className='validation-error'>
                  {`Email ${validation?.confirm_password?.message}`}
                </small> : ""
              }
            </div>
          </div>
          <div className="password_hint d-flex mt-4">
            <i className="fa-solid fa-circle-info me-2 mt-1"></i>
            <p>
              Password must contain at least 1 uppercase letter, 1 lowercase
              letter, 1 number, 1 special character, and be at least 8
              characters long.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAdmin;
