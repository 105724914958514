import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getProfileDetails, UpdateProfileDetails } from '../../common/Api'
import { NonEmptyValidation, PhoneNumberValidation, StringValidation } from '../../common/validate'
import { errorMsg, successMsg } from '../../containers/Toastify'
import { useNavigate } from 'react-router-dom'

const ProfileUpdate = () => {
    const navigate = useNavigate()
    const { token } = useSelector((state) => state.login)
    const [data, setData] = useState()
    const [validation, setValidation] = useState({})
    const getData = useCallback(async () => {
        const response = await getProfileDetails(token)
        if (response && response.statusCode === 200) {
            setData(response?.data)
        }
    }, [token])
    console.log(validation)
    useEffect(() => {
        getData()
    }, [getData])

    const handleChange = (e, key) => {
        setData({
            ...data,
            [key]: e
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const validate = {}
        validate.name = NonEmptyValidation(data?.name)
        validate.phone_number = PhoneNumberValidation(data?.phone_number)
        const checkInput = Object.values(validate).every((a) => a.status === true)
        setValidation(validate)
        if (checkInput) {
            const payload = {
                name: data?.name,
                phone_number: data?.phone_number
            }

            const response = await UpdateProfileDetails(payload, token)
            if (response && response.statusCode === 200) {
                successMsg(response?.message)
                navigate(-1)
            } else {
                errorMsg(response?.message)
            }
        }

    }
    return (
        <div className='admin-content-body'>
            <div className='admin-form-heading d-flex justify-content-between align-items-center'>
                <p className='mb-0'>Profile Details</p>
                <button className='admin-btn' onClick={handleSubmit}>Update</button>
            </div>
            <div className='Admin-form-content'>
                <div className='row'>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                        <div className='profile-image-card'>
                            <h1 className='mb-0'>{data?.name.slice(0, 2).toUpperCase()}</h1>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Name</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.name}
                                onInput={(e) => handleChange(e.target.value, 'name')}
                            />
                            {validation?.name?.message ?
                                <small className='validation-error'>
                                    {`Name ${validation?.name?.message}`}
                                </small> : ""
                            }
                        </div>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Phone Number</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.phone_number}
                                onInput={(e) => handleChange(e.target.value, 'phone_number')}
                            />
                            {validation?.phone_number?.message ?
                                <small className='validation-error'>
                                    {`Phone Number ${validation?.phone_number?.message}`}
                                </small> : ""
                            }
                        </div>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Email Id</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.email_id}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileUpdate