export const menu = [
  {
    path: "/admin/dashboard",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Dashboard",
  },
  {
    path: "/admin/adminManagement",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Admin Management",
  },
  {
    path: "/admin/appConfiguration",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "App Configuration",
  },
  {
    title: "User",
    path: "/admin/userDetails",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "User Details",
  },
  {
    path: "/admin/referred",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Referral",
  },
  {
    path: "",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Filter",
    subMenu: [
      {
        path: "/admin/filter/category",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Category",
      },
      {
        path: "/admin/filter/subCategory",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Sub Category",
      },
    ],
  },
  {
    path: "",
    icon: <i className="fa-solid fa-user"></i>,
    name: "Help And Support",
    subMenu: [
      {
        path: "/admin/helpAndSupport/subject",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Subject",
      },
      {
        path: "/admin/helpAndSupport/query",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Query",
      },
      {
        path: "/admin/helpAndSupport/ticket",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Ticket",
      },
    ],
  },
  {
    path: "",
    icon: <i className="fa-solid fa-user"></i>,
    name: "FAQ",
    subMenu: [
      {
        path: "/admin/faq/category",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Category",
      },
      {
        path: "/admin/faq/questionAnswer",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Q & A",
      },
    ],
  },
  {
    path: "/admin/user/termsAndCondition",
    icon: <i className="fa-solid fa-pen-to-square"></i>,
    name: "Terms And Condition",
  },
  {
    path: "/admin/user/privacyPolicy",
    icon: <i className="fa-regular fa-paper-plane"></i>,
    name: "Privacy Policy",
  },
  {
    title: "Expert",
    path: "/admin/ExpertDetails",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "EXpert Details",
  },
  {
    path: "/admin/kyc-management",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "KYC Management",
  },
  {
    path: "",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Filter",
    subMenu: [
      {
        path: "/admin/expert/filter/category",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Category",
      },
      {
        path: "/admin/expert/filter/subCategory",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Sub Category",
      },
    ],
  },
  {
    path: "",
    icon: <i className="fa-solid fa-user"></i>,
    name: "Help And Support",
    subMenu: [
      {
        path: "/admin/expert/helpAndSupport/subject",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Subject",
      },
      {
        path: "/admin/expert/helpAndSupport/query",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Query",
      },
      {
        path: "/admin/expert/helpAndSupport/ticket",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Ticket",
      },
    ],
  },
  {
    path: "",
    icon: <i className="fa-solid fa-user"></i>,
    name: "FAQ",
    subMenu: [
      {
        path: "/admin/expert/faq/category",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Category",
      },
      {
        path: "/admin/expert/faq/questionAnswer",
        icon: <i className="fa-solid fa-user"></i>,
        name: "Q & A",
      },
    ],
  },
  {
    path: "/admin/expert/termsAndCondition",
    icon: <i className="fa-solid fa-pen-to-square"></i>,
    name: "Terms And Condition",
  },
  {
    path: "/admin/expert/privacyPolicy",
    icon: <i className="fa-regular fa-paper-plane"></i>,
    name: "Privacy Policy",
  },
  {
    path: "/admin/expert/payouts",
    icon: <i className="fa-regular fa-paper-plane"></i>,
    name: "Payouts",
  },
  {
    title: "Web",
    path: "/admin/userEnquiries",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "User Enquiries",
  },
  {
    path: "/admin/socialMediaLinks",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Social Media Links",
  },
];

export const ExpertMenu = [
  {
    path: "/expert/app/dashboard",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Dashboard",
  },
  {
    path: "/expert/expressTips",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Express Tips",
  },
  {
    path: "/expert/tips",
    icon: <i className="fa-solid fa-list-ul"></i>,
    name: "Tips",
  },
];
