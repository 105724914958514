import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Logo from "../../assets/images/web-logo.png";
import apple from "../../assets/images/apple.png";
import playstore from "../../assets/images/playstores.png";
import banrght from "../../assets/images/ban-rght.png";
import banbottom from "../../assets/images/ban-bottom.png";
import line1 from "../../assets/images/ver-line.png";
import horline from "../../assets/images/hor-line.png";
import sec_img from "../../assets/images/sec-left-img.png";
import abt_left from "../../assets/images/abt-left-img.png";
import chartline from "../../assets/images/chart-line.png";
import platform_img from "../../assets/images/platform-img.png";
import user_left from "../../assets/images/user-left-img.png";
import user_card from "../../assets/images/user-card.png";
import expert_img from "../../assets/images/expert-img.png";
import expert_bottom from "../../assets/images/exp-bottom.png";
import expect_card from "../../assets/images/expert-card.png";
import market from "../../assets/images/market.png";
import gift from "../../assets/images/gift.png";
import jline from "../../assets/images/journey-line.png";
import jleft from "../../assets/images/journey-left.png";
import jright from "../../assets/images/journey-right.png";
import twitter from "../../assets/images/twitter.png";
import insta from "../../assets/images/insta.png";
import linkedn from "../../assets/images/linkedn.png";
import user_line_left from "../../assets/images/user-head-left.png";
import user_line_rght from "../../assets/images/user-head-right.png";
import expert_line_right from "../../assets/images/expert-head-right.png";
import expert_line_left from "../../assets/images/expert-head-left.png";
import journey_line from "../../assets/images/journey-head-line.png";
import facebk from "../../assets/images/facebk.png";
import videoimg from "../../assets/images/login-img.png";
import video_1 from "../../assets/video/video-1.mp4";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
} from "../../common/validate";
import { createUserEnquiries } from "../../common/Api";
import CustomModal from "../../components/CustomModal";
const Welcome = () => {
  const navigate = useNavigate();
  const [Counteron, SetcounterOn] = useState(false);
  const [watchVideo, SetWatchVideo] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const videoArray = [
    {
      videonum: "1",
      videoTitle: "Video 1",
      VideoDesc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      videonum: "2",
      videoTitle: "Video 2",
      VideoDesc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      videonum: "3",
      videoTitle: "Video 3",
      VideoDesc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ];
  const handleWatchVideo = (index) => {
    SetWatchVideo(index);
  };
  const [validation, setValidation] = useState({});
  const [open, setOpen] = useState(false);

  const handleChange = (e, key) => {
    setFormData({
      ...formData,
      [key]: e,
    });
  };

  const closeModal = () => {
    setOpen(false);
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    });
  };
  console.log(formData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = NonEmptyValidation(formData.name);
    validate.email = EmailValidation(formData.email, "Email");
    validate.phoneNumber = PhoneNumberValidation(formData.phoneNumber);
    validate.message = NonEmptyValidation(formData.message);
    setValidation(validate);
    const checkInput = Object.values(validate).every(
      (val) => val.status === true
    );
    if (checkInput) {
      const payload = {
        name: formData.name,
        email_id: formData.email,
        phone_number: formData.phoneNumber,
        message: formData.message,
      };

      const response = await createUserEnquiries(payload);
      if (response && response?.statusCode === 200) {
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
        setValidation();
        setOpen(true);
      }
    }
  };
  useEffect(() => {
    AOS.init({
      duration: 1300,
      once: false,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <div className="parent-section">
        <div className="navbars">
          <nav className="navbar navbar-expand-lg pt-3 pb-3">
            <div className="container nav-flex ">
              <div>
                <img src={Logo} alt=".." className="logo-img" />
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav  mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link style-9" href="#tradojourney">
                      Trado Journey
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link style-9" href="#platformusage">
                      Platform Usage
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link style-9" href="#user">
                      User
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link style-9" href="#expert">
                      Expert
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link style-9" href="#market">
                      Market
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link style-9" href="#demo">
                      Demo
                    </a>
                  </li>
                </ul>

                <button
                  className="create-btn"
                  onClick={() => navigate("/expert-login")}
                >
                  Create Tip
                </button>
              </div>
            </div>
          </nav>
        </div>
        <section className="webpage animation_overflow_banner">
          <div className="container">
            <div className="row position-relative justify-content-center">
              <div className="col-lg-4">
                <div className="banner-right">
                  <h4 className="banner-head1" data-aos="zoom-in">
                    Trade
                  </h4>
                  <h4 className="banner-head2" data-aos="fade-down-right">
                    Like a Pro!{" "}
                  </h4>
                  <h4 className="banner-head2" data-aos="zoom-in">
                    XXXXXX
                  </h4>
                  <div
                    className="banner-input-div mt-4 position-relative"
                    data-aos="fade-down-right"
                  >
                    <input
                      className="banner-input"
                      placeholder="Enter Mobile Number"
                    />
                    <span>
                      <i className="fa-solid fa-arrow-right"></i>
                    </span>
                  </div>

                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="mt-2"
                  >
                    <i className="fa-solid fa-circle-info me-1"></i>
                    <p className="head-para">
                      Get an instant SMS with free App download link
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="mt-3"
                  >
                    <img
                      src={apple}
                      alt=".."
                      className="logo-img"
                      // data-aos="fade-right"
                    />{" "}
                    <img
                      src={playstore}
                      alt=".."
                      className="logo-img ms-3"
                      // data-aos="fade-left"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <div className="banner-left" data-aos="zoom-out-left">
                  <img src={banrght} alt=".." className="ban-left-img" />
                  <img
                    src={banbottom}
                    alt=".."
                    className="ban-bottom mobile-ban-bottom"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section-2 animation_overflow_section2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section2-left">
                <img
                  src={sec_img}
                  alt=""
                  className="sec-left-img"
                  data-aos="fade-right"
                />
              </div>
            </div>
            <div className="col-lg-6 my-auto">
              <div className="section2-right">
                <div className="counter-div1 position-relative">
                  <div className="counter-box1" data-aos="fade-down-right">
                    <ScrollTrigger
                      onEnter={() => SetcounterOn(true)}
                      onExit={() => SetcounterOn(false)}
                    >
                      <p className="counter">
                        {Counteron && (
                          <CountUp start={0} end={143} duration={2} delay={0} />
                        )}
                        k+
                      </p>
                    </ScrollTrigger>
                    <p className="counter-para">No Of User</p>

                    <img src={horline} alt="" className="line1-horizontal" />
                  </div>
                  <img src={line1} alt="" className="lin1-vertical" />
                  <div position-relative data-aos="fade-down-left">
                    <ScrollTrigger
                      onEnter={() => SetcounterOn(true)}
                      onExit={() => SetcounterOn(false)}
                    >
                      <p className="counter">
                        {Counteron && (
                          <CountUp start={0} end={100} duration={2} delay={0} />
                        )}
                        k+
                      </p>
                    </ScrollTrigger>
                    <p className="counter-para">No Of Expert</p>
                    <img src={horline} alt="" className="line1-horizontal" />
                  </div>
                </div>
                <div className="counter-div1 position-relative">
                  <div data-aos="fade-down-right">
                    <ScrollTrigger
                      onEnter={() => SetcounterOn(true)}
                      onExit={() => SetcounterOn(false)}
                    >
                      <p className="counter">
                        {Counteron && (
                          <CountUp start={0} end={100} duration={2} delay={0} />
                        )}
                        K+
                      </p>
                    </ScrollTrigger>
                    <p className="counter-para">No of Tips viewed</p>
                  </div>
                  <img src={line1} alt="" className="lin1-vertical" />
                  <div data-aos="fade-down-left">
                    <ScrollTrigger
                      onEnter={() => SetcounterOn(true)}
                      onExit={() => SetcounterOn(false)}
                    >
                      <p className="counter">
                        {Counteron && (
                          <CountUp start={0} end={126} duration={2} delay={0} />
                        )}
                        k+
                      </p>
                    </ScrollTrigger>
                    <p className="counter-para">No of tips posted</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutus-section animation_overflow">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="about-left position-relative"
                data-aos="fade-down-left"
              >
                <img src={abt_left} alt="../" className="about-left-img" />
                <img src={banbottom} alt=".." className="ban-bottom" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-right">
                <h3 className="abt-head" data-aos="fade-down">
                  About <span style={{ color: "#60FFCB" }}>Us</span>
                </h3>
                <div className="about-points mt-4" data-aos="zoom-out-right">
                  <div>
                    <div>
                      <i className="fa-solid fa-check"></i>
                    </div>
                  </div>

                  <p className="abt-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div className="about-points" data-aos="zoom-out-left">
                  <div>
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p className="abt-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div className="about-points" data-aos="zoom-out-right">
                  <div>
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p className="abt-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div className="about-points" data-aos="zoom-out-left">
                  <div>
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p className="abt-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div className="mt-4" position-relative data-aos="fade-up">
                  <p className="download-btn">Download The App</p>
                  <span>
                    <i className="fa-solid fa-arrow-right arrow-2"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="platform-usage-section animation_overflow"
        id="platformusage"
      >
        <div className="container">
          <h3 className="abt-head2" data-aos="zoom-in">
            Platform <span style={{ color: "#60FFCB" }}>Usage</span>
          </h3>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-3">
              <div className="platform-left">
                <div className="top-card" data-aos="zoom-out-right">
                  <img src={chartline} alt="../" className="chartline" />
                  <h3 className="card-head">Lorem Ipsum</h3>
                  <p className="card-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum 1500s
                  </p>
                </div>
                <div className="top-card mt-3" data-aos="zoom-out-right">
                  <img src={chartline} alt="../" className="chartline" />
                  <h3 className="card-head">Lorem Ipsum</h3>
                  <p className="card-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum 1500s
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="platform-center" data-aos="zoom-out">
                <img src={Logo} alt="../" className="platform-logo" />
                <img
                  src={platform_img}
                  alt="../"
                  className="paltform-center-img"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="platform-right">
                <div className="top-card" data-aos="zoom-out-left">
                  <img src={chartline} alt="../" className="chartline" />
                  <h3 className="card-head">Lorem Ipsum</h3>
                  <p className="card-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum 1500s
                  </p>
                </div>
                <div className="top-card mt-3" data-aos="zoom-out-left">
                  <img src={chartline} alt="../" className="chartline" />
                  <h3 className="card-head">Lorem Ipsum</h3>
                  <p className="card-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum 1500s
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="user-section animation_overflow" id="user">
        <div className="container">
          <div className="position-relative">
            <h3 className="abt-head3" data-aos="zoom-out-down">
              User
            </h3>
            <img
              src={user_line_left}
              alt="../"
              className="user-left-line"
              data-aos="fade-right"
            />
            <img
              src={user_line_rght}
              alt=".."
              className="user-right-line"
              data-aos="fade-left"
            />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="user-left position-relative" data-aos="zoom-out">
                <img src={user_left} alt="../" className="user-left-img" />
                <img src={banbottom} alt=".." className="ban-bottom" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="user-right">
                <div className="user-flex">
                  <div className="user-card1 position-relative">
                    <div data-aos="fade-up">
                      <img
                        src={user_card}
                        alt="../"
                        className="user-card-img"
                      />
                      <h4 className="card-head">Lorem Ipsum</h4>
                      <p className="card-para-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum 1500s
                      </p>
                    </div>
                    <img src={line1} alt="" className="user-line-vertical" />
                    <img src={horline} alt="" className="line2-horizontal" />
                  </div>
                  <div className="user-card position-relative">
                    <div data-aos="fade-down">
                      <img
                        src={user_card}
                        alt="../"
                        className="user-card-img"
                      />
                      <h4 className="card-head">Lorem Ipsum</h4>
                      <p className="card-para-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum 1500s
                      </p>
                    </div>
                    <img src={horline} alt="" className="line2-horizontal" />
                  </div>
                </div>
                <div className="user-flex">
                  <div className="user-card1 position-relative">
                    <div data-aos="fade-down">
                      <img
                        src={user_card}
                        alt="../"
                        className="user-card-img"
                      />
                      <h4 className="card-head">Lorem Ipsum</h4>
                      <p className="card-para-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum 1500s
                      </p>
                    </div>
                    <img src={line1} alt="" className="user-line-vertical" />
                  </div>
                  <div className="user-card" data-aos="fade-up">
                    <img src={user_card} alt="../" className="user-card-img" />
                    <h4 className="card-head">Lorem Ipsum</h4>
                    <p className="card-para-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum 1500s
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Expert-section animation_overflow" id="expert">
        <div className="container">
          <div className="position-relative">
            <h3 className="abt-head4" data-aos="zoom-out-up">
              Expert
            </h3>
            <img
              src={expert_line_left}
              alt="../"
              className="expert-left-line"
              data-aos="fade-right"
            />
            <img
              src={expert_line_right}
              alt=".."
              className="expert-right-line"
              data-aos="fade-left"
            />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="user-right">
                <div className="user-flex">
                  <div className="user-card1 position-relative">
                    <div data-aos="fade-down-left">
                      <img
                        src={expect_card}
                        alt="../"
                        className="user-card-img"
                      />
                      <h4 className="card-head">Lorem Ipsum</h4>
                      <p className="card-para-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum 1500s
                      </p>
                    </div>
                    <img src={line1} alt="" className="user-line-vertical" />
                    <img src={horline} alt="" className="line2-horizontal" />
                  </div>
                  <div className="user-card position-relative">
                    <div data-aos="fade-down-right">
                      <img
                        src={expect_card}
                        alt="../"
                        className="user-card-img"
                      />
                      <h4 className="card-head">Lorem Ipsum</h4>
                      <p className="card-para-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum 1500s
                      </p>
                    </div>
                    <img src={horline} alt="" className="line2-horizontal" />
                  </div>
                </div>
                <div className="user-flex">
                  <div className="user-card1 position-relative">
                    <div data-aos="fade-down-left">
                      <img
                        src={expect_card}
                        alt="../"
                        className="user-card-img"
                      />
                      <h4 className="card-head">Lorem Ipsum</h4>
                      <p className="card-para-2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum 1500s
                      </p>
                    </div>
                    <img src={line1} alt="" className="user-line-vertical" />
                  </div>
                  <div className="user-card" data-aos="fade-down-right">
                    <img
                      src={expect_card}
                      alt="../"
                      className="user-card-img"
                    />
                    <h4 className="card-head">Lorem Ipsum</h4>
                    <p className="card-para-2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum 1500s
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="user-left position-relative" data-aos="fade-left">
                <img src={expert_img} alt="../" className="expert-right-img" />
                <img src={expert_bottom} alt=".." className="ban-bottom" />
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </section>
      <section
        className="market-section animation_overflow_section2"
        id="market"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section2-left" data-aos="zoom-in">
                <img src={market} alt="" className="market-left-img" />
              </div>
            </div>
            <div className="col-lg-6 my-auto">
              <div className="about-right">
                <h3 className="abt-head" data-aos="zoom-out">
                  Market
                </h3>
                <div className="about-points mt-4" data-aos="fade-down">
                  <div>
                    <i class="fa-solid fa-check"></i>
                  </div>

                  <p className="abt-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div className="about-points" data-aos="fade-up">
                  <div>
                    <i class="fa-solid fa-check"></i>
                  </div>
                  <p className="abt-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div className="about-points" data-aos="fade-down">
                  <div>
                    <i class="fa-solid fa-check"></i>
                  </div>
                  <p className="abt-para mb-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                {/* <div className="about-points">
                  <i class="fa-solid fa-check"></i>
                  <p className="abt-para">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div> */}
                <div className="mt-4 position-relative" data-aos="fade-right">
                  <p className="download-btn">Download The App</p>
                  <span>
                    <i class="fa-solid fa-arrow-right arrow-2"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="watchdemo-section animation_overflow" id="demo">
        <div className="container">
          <h3 className="abt-head_video" data-aos="zoom-in">
            Watch <span style={{ color: "#60FFCB" }}>Demo</span>
          </h3>
          <div className="row">
            <div className="col-lg-6">
              <div className="watch-demo" data-aos="fade-right">
                <video className="video" controls>
                  <source src={video_1} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-lg-6">
              {videoArray.map((item, index) => {
                return (
                  <>
                    <div className="watchdemo-right" data-aos="fade-left">
                      <div
                        className={
                          index === watchVideo
                            ? "video-hover_active mb-2"
                            : "video-hover mb-2"
                        }
                        key={index}
                        onClick={() => handleWatchVideo(index)}
                      >
                        <div>
                          <div className="video-number">{item.videonum}</div>
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                          className="ms-3"
                        >
                          <h5 className="video-head">{item.videoTitle}</h5>
                          <p className="video-para">{item.VideoDesc}</p>
                        </div>
                      </div>
                      {/* <div
                  className={watchVideo ? "video-hover_active" : "video-hover"}
                  // data-aos="fade-down-right"
                  onClick={() => SetWatchVideo(!watchVideo)}
                >
                  <div>
                    <div className="video-number">2</div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="ms-3"
                  >
                    <h5 className="video-head">Video</h5>
                    <p className="video-para">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div>
                <div
                  className={watchVideo ? "video-hover_active" : "video-hover"}
                  // data-aos="fade-down-right"
                  onClick={() => SetWatchVideo(!watchVideo)}
                >
                  <div>
                    <div className="video-number">3</div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    className="ms-3"
                  >
                    <h5 className="video-head">Video</h5>
                    <p className="video-para">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </div> */}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="faq_section animation_overflow">
        <div className="container">
          <h3 className="abt-head2 mb-5" data-aos="zoom-out-down">
            FAQ<span style={{ color: "#60FFCB" }}>s</span>
          </h3>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-5">
              <div className="user_faq">
                <h4 className="faq_head mb-3" data-aos="fade-down-right">
                  User
                </h4>
                {/*  */}
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item" data-aos="fade-down">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is shown by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" data-aos="fade-up">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" data-aos="fade-down">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" data-aos="fade-up">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" data-aos="fade-down">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed accordion-button_last"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <div className="expert_faq">
                <h4 className="faq_head2 mb-3" data-aos="fade-down-left">
                  Expert
                </h4>
                {/*  */}
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item" data-aos="fade-up">
                    <h2 class="accordion-header" id="headingSix">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="true"
                        aria-controls="collapseSix"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordiSixxample"
                    >
                      <div class="accordion-body">
                        It is shown by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" data-aos="fade-down">
                    <h2 class="accordion-header" id="headingSeven">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" data-aos="fade-up">
                    <h2 class="accordion-header" id="headingEight">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" data-aos="fade-down">
                    <h2 class="accordion-header" id="headingNine">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item" data-aos="fade-up">
                    <h2 class="accordion-header" id="headingTen">
                      <button
                        class="accordion-button collapsed accordion-button_last"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        What is Lorem Ipsum?
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        It is hidden by default, until the collapse plugin adds
                        the appropriate classes that we use to style each
                        element. These classes control the overall appearance,
                        as well as the showing and hiding via CSS transitions.
                        You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just
                        about any HTML can go within the{" "}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="ourjourney_section position-relative animation_overflow"
        id="tradojourney"
      >
        <div className="container">
          <div className="position-relative">
            <h5 className="our-head" data-aos="zoom-in">
              Our Journey
            </h5>
            <img
              src={journey_line}
              alt="."
              className="journey_line_left"
              data-aos="fade-right"
            />
            <img
              src={journey_line}
              alt="."
              className="journey_line_right"
              data-aos="fade-left"
            />
          </div>

          <h4 className="our-subhead" data-aos="fade-up">
            Lorem ipsum dolor sit amet
          </h4>
          <h4 className="our-subhead" data-aos="fade-down">
            sit amet
          </h4>
          <img src={jline} alt="." className="journey_line" />
          <div className="journey-top">
            <div className="row  justify-content-center">
              <div className="col-lg-2 my-auto">
                <h1 className="journey-year" data-aos="fade-right">
                  2023
                </h1>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <div className="journey-details" data-aos="fade-left">
                  <div className="journey_img_div">
                    <img src={gift} alt="../" className="journey-img" />
                  </div>

                  <p className="journey_para my-auto mx-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    elementum dolor lorem, a sodales dui dictum ut.
                  </p>
                </div>
              </div>
            </div>
            <img src={jright} alt="." className="journey_arrow_right" />
          </div>
          <div className="journey-top">
            <div className="row  justify-content-center">
              <div className="col-lg-6">
                <div className="journey-details" data-aos="fade-right">
                  <p className="journey_para my-auto mx-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    elementum dolor lorem, a sodales dui dictum ut.
                  </p>
                  <div className="journey_img_div">
                    <img src={gift} alt="../" className="journey-img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-2 my-auto">
                <h1 className="journey-year" data-aos="fade-left">
                  2023
                </h1>
              </div>
            </div>
            <img src={jleft} alt="." className="journey_arrow_left" />
          </div>
          <div className="journey-top">
            <div className="row  justify-content-center">
              <div className="col-lg-2 my-auto">
                <h1 className="journey-year" data-aos="fade-right">
                  2023
                </h1>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-6">
                <div className="journey-details" data-aos="fade-left">
                  <div className="journey_img_div">
                    <img src={gift} alt="../" className="journey-img" />
                  </div>
                  <p className="journey_para my-auto mx-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    elementum dolor lorem, a sodales dui dictum ut.
                  </p>
                </div>
              </div>
            </div>
            <img src={jright} alt="." className="journey_arrow_left" />
          </div>
          <div className="journey-top">
            <div className="row  justify-content-center">
              <div className="col-lg-6">
                <div className="journey-details" data-aos="fade-right">
                  <p className="journey_para mx-4 my-auto ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    elementum dolor lorem, a sodales dui dictum ut.
                  </p>
                  <div className="journey_img_div">
                    <img src={gift} alt="../" className="journey-img" />
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-2 my-auto">
                <h1 className="journey-year" data-aos="fade-left">
                  2023
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get_in_touch_section animation_overflow">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="get_touch_left position-relative"
                data-aos="zoom-out-right"
              >
                <img src={abt_left} alt="../" className="get-left-img" />
                <img src={banbottom} alt="./." className="ban-bottom" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="get_touch_right">
                <h1 className="abt-head5" data-aos="zoom-out-left">
                  Get In <span style={{ color: "#60FFCB" }}>Touch</span>
                </h1>
                <div>
                  <div className="form_input" data-aos="fade-down-right">
                    <label className="mb-1">Name</label>
                    <input
                      name="name"
                      placeholder="Enter Name"
                      value={formData?.name}
                      onChange={(e) => handleChange(e.target.value, "name")}
                    />
                    {validation?.name?.message ? (
                      <small className="validation-error">
                        {`Name ${validation?.name?.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form_input" data-aos="fade-down-left">
                    <label className="mb-1">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      value={formData?.email}
                      onChange={(e) => handleChange(e.target.value, "email")}
                    />
                    {validation?.email?.message ? (
                      <small className="validation-error">
                        {`${validation?.email?.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form_input" data-aos="fade-down-right">
                    <label className="mb-1">Phone Number</label>
                    <input
                      type="tel"
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      value={formData?.phoneNumber}
                      onChange={(e) =>
                        handleChange(e.target.value, "phoneNumber")
                      }
                    />
                    {validation?.phoneNumber?.message ? (
                      <small className="validation-error">
                        {`Phone Number ${validation?.phoneNumber?.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form_input" data-aos="fade-down-left">
                    <label className="mb-1">Message</label>
                    <textarea
                      name="name"
                      placeholder="Type Your Query Here"
                      value={formData?.message}
                      onChange={(e) => handleChange(e.target.value, "message")}
                    />
                    {validation?.message?.message ? (
                      <small className="validation-error">
                        {`Message ${validation?.message?.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    className="ok-btn mt-4 p-2 rounded fw-bold"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Send My Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer_section">
        <div className="container">
          <div className="row">
            <div className="footer_top">
              <div>
                <img src={Logo} alt="." className="footer_img me-5" />
                <img src={apple} alt="." className="footer_img me-3" />
                <img src={playstore} alt="." className="footer_img" />
              </div>
              <div className="my-auto footer_icon">
                <img src={twitter} alt="./" className="socail-icon me-2" />
                <img src={insta} alt="./" className="socail-icon me-2" />
                <img src={linkedn} alt="./" className="socail-icon me-2" />
                <img src={facebk} alt="./" className="socail-icon" />
              </div>
            </div>
            <div className="footer_bototm">
              <div style={{ display: "flex" }} className="term_privay">
                <p className="me-5">Privacy policy</p>
                <p>Terms and conditions</p>
              </div>
              <div>
                <p>©2024 Trado. All Rights Reserved.</p>
              </div>
              <div className="" style={{ display: "flex" }}>
                <i class="fa-regular fa-envelope mt-1 me-2"></i>
                <p className="mailtotag">
                  <a href="mailto:info@trado.com">info@trado.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content logout-modal p-5">
          <i
            class="fa-solid fa-circle-xmark home-close-btn"
            onClick={closeModal}
          ></i>
          <div className="m-auto">
            <img src={Logo} className="modal-logo" />
          </div>

          <h5 className="text-center text-white modal-message">
            Your Messgae Has Been Submitted Successfully!!!
          </h5>
        </div>
      </CustomModal>
    </>
  );
};

export default Welcome;
