import axios from "axios";
import { url } from "./BaseUrl";

const ApiBaseUrl = url + "/api/v1"

const makeRequest = async (method, url, payload = {}, token = '') => {
    try {
        const response = await axios({
            method,
            url,
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                ...(token && { Authorization: `Bearer ${token}` })
            },
            data: method !== 'get' ? payload : null
        });
        return response.data;
    } catch (error) {
        return { error: error };
    }
};

export const AdminLogin = async (payload) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/auth/login`, payload);
};

export const ExpertWebLogin = async (payload) => {
    return makeRequest('post', `${ApiBaseUrl}/user/auth/login`, payload);
};

export const ExpertWebVerifyOtp = async (payload) => {
    return makeRequest('post', `${ApiBaseUrl}/user/auth/verify_otp`, payload);
};

export const getAppConfiguration = async (token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/app_configuration/find`, {}, token);
};

export const updatetAppConfiguration = async (payload, token) => {
    return makeRequest('put', `${ApiBaseUrl}/admin/app_configuration/update`, payload, token);
};

export const AdminForgotPassword = async (payload) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/auth/forgot_password`, payload);
};

export const AdminVerifyOtp = async (payload) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/auth/verify_email_otp`, payload);
};

export const AdminResetPassword = async (payload) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/auth/forgot_change_password`, payload);
};

export const AdminChangePassword = async (payload, token) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/auth/change_password`, payload, token);
};
//=====================================   GET IN TOUCH   ========================================/

export const createUserEnquiries = async (payload) => {
    return makeRequest('post', `${ApiBaseUrl}/user/get_in_touch/submit`, payload);
};

export const getUserEnquiries = async (token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/get_in_touch/find_all`, {}, token);
};

export const getSpecificUserEnquiry = async (id, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/get_in_touch/find_particular?_id=${id}`, {}, token);
};

//==================================================================================================/
export const getCms = async (name, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/cms_document/find?cms_document_type=${name}`, {}, token);
};
export const updateCms = async (payload, token) => {
    return makeRequest('put', `${ApiBaseUrl}/admin/cms_document/update`, payload, token);
};

export const createAdmin = async (payload, token) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/create`, payload, token);
};

export const findAllAdmin = async (token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/find_all`, {}, token);
};

export const findSpecificAdmin = async (id, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/find_particular?_id=${id}`, {}, token);
};

export const updateAdmin = async (payload, token) => {
    return makeRequest('put', `${ApiBaseUrl}/admin/update`, payload, token);
};

export const deleteAdmin = async (payload, token) => {
    return makeRequest('delete', `${ApiBaseUrl}/admin/delete`, payload, token);
};

export const createFaqUserCategory = async (payload, token) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/faq_category/create`, payload, token);
};

export const getFaqCategory = async (userType, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/faq_category/find_all?category_type=${userType}`, {}, token);
};

export const getSpecificFaqUserCategory = async (id, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/faq_category/find_particular?_id=${id}`, {}, token);
};

export const deleteFaqUserCategory = async (payload, token) => {
    return makeRequest('delete', `${ApiBaseUrl}/admin/faq_category/delete`, payload, token);
};

export const updateFaqUserCategory = async (payload, token) => {
    return makeRequest('put', `${ApiBaseUrl}/admin/faq_category/update`, payload, token);
};

// /======================       FAQ            ==================================================/

export const createFaq = async (payload, token) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/faq/create`, payload, token);
};

export const getFaq = async (userType, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/faq/find_all?category_type=${userType}`, {}, token);
};

export const getSpecificFaq = async (id, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/faq/find_particular?_id=${id}`, {}, token);
};

export const deleteFaq = async (payload, token) => {
    return makeRequest('delete', `${ApiBaseUrl}/admin/faq/delete`, payload, token);
};

export const updateFaq = async (payload, token) => {
    return makeRequest('put', `${ApiBaseUrl}/admin/faq/update`, payload, token);
};


// /======================       Dropdown menu        ==================================================/
export const getProfileDetails = async (token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/profile/find`, {}, token);
};

export const UpdateProfileDetails = async (payload, token) => {
    return makeRequest('put', `${ApiBaseUrl}/admin/profile/update`, payload, token);
};


// /======================    Help and Support  Master  ======================================/

export const createHelpnSupport = async (payload, token) => {
    return makeRequest('post', `${ApiBaseUrl}/admin/help_and_support/master_setup/create`, payload, token);
};

export const getHelpnSupport = async (userType, masterType, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/help_and_support/master_setup/find_all?master_user_type=${userType}&master_type=${masterType}`, {}, token);
};

export const getSpecificHelpnSupport = async (id, token) => {
    return makeRequest('get', `${ApiBaseUrl}/admin/help_and_support/master_setup/find_particular?_id=${id}`, {}, token);
};

export const deleteHelpnSupport = async (payload, token) => {
    return makeRequest('delete', `${ApiBaseUrl}/admin/help_and_support/master_setup/delete`, payload, token);
};

export const updateHelpnSupport = async (payload, token) => {
    return makeRequest('put', `${ApiBaseUrl}/admin/help_and_support/master_setup/update`, payload, token);
};