import React from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
const UserDetails = () => {
  const navigate = useNavigate();
  const hanldeDetailsClick = () => {
    navigate("/admin/userIndividualDetails");
  };
  const columns = [
    {
      name: "S.No",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <i class="fa-solid fa-trash-arrow-up admin-delete-icon"></i>
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <i
                class="fa-solid fa-eye admin-view-icon"
                onClick={hanldeDetailsClick}
              ></i>
            </div>
          );
        },
      },
    },
  ];

  const data = [
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
    {
      name: "kishore",
      email: "kishore@gmail.com",
      phoneNumber: 8489519449,
      status: "active",
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  return (
    <div className="">
      <MUIDataTable
        title={"User Enquiries List"}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default UserDetails;
