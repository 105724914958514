import React, { useState, useCallback, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Link, resolvePath } from "react-router-dom";
import CustomModal from "../../../components/CustomModal";
import { useNavigate } from "react-router-dom";
import { deleteAdmin, findAllAdmin, findSpecificAdmin, updateAdmin } from "../../../common/Api";
import { useSelector } from "react-redux";
import { errorMsg, successMsg } from "../../../containers/Toastify";
const AdminList = () => {
  const navigate = useNavigate();
  const hanldeEditClick = (id) => {
    navigate(`/admin/EditAdmin/${id}`);
  };

  const { token } = useSelector((state) => state.login)
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)
  const [specificId, setSpecificId] = useState("")
  const [deleteId, setDeleteId] = useState("")
  const [updateId, setUpdateId] = useState("")
  const [specificData, setSpecificData] = useState("")
  const getData = useCallback(async () => {
    const response = await findAllAdmin(token)
    if (response && response?.statusCode === 200) {
      setData(response?.data?.user_details)
    }
  }, [token])

  const getSpecificData = async () => {
    const response = await findSpecificAdmin(specificId, token)
    if (response && response?.statusCode === 200) {
      setSpecificData(response?.data)
    }
  }

  const handleUpdate = async (id, status) => {
    let stat
    if (status === "active") {
      stat = "inactive"
    } else if (status === "inactive") {
      stat = "active"
    }
    const payload = {
      _id: id,
      status: stat
    }
    const response = await updateAdmin(payload, token)
    if (response && response.statusCode === 200) {
      successMsg(response?.message)
      getData()
    } else {
      errorMsg(response?.message)
    }
  }

  const handleDelete = async () => {
    const payload = {
      _id: deleteId
    }
    const response = await deleteAdmin(payload, token)
    if (response && response.statusCode === 200) {
      successMsg(response?.message)
      getData()
    }
  }
  useEffect(() => {
    getSpecificData()
  }, [specificId])

  console.log(specificData)


  useEffect(() => {
    getData()
  }, [open])

  console.log(data)

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "phone_number",
      label: "Phone Number",
    },
    {
      name: "email_id",
      label: "Email",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) => {
          const status = tableMeta.rowData[4] === "active" ? true : false;
          return (
            <div className="d-flex align-items-center justify-content-center">
              <i
                class="fa-solid fa-trash-arrow-up admin-delete-icon"
                onClick={() => {
                  setDeleteId(tableMeta.rowData[0])
                  setOpen(true)
                }}
              ></i>
              <label
                className="switch"
              >
                <input
                  type="checkbox"
                  checked={status}
                  onChange={() => handleUpdate(tableMeta.rowData[0], tableMeta.rowData[4])}

                />
                <span className="slider round"></span>
              </label>
              <i
                class="fa-solid fa-user-pen admin-view-icon"
                onClick={() => hanldeEditClick(tableMeta.rowData[0])}
              ></i>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  return (
    <div className="">
      <div className="admin_btn_right">
        <Link to="/admin/createAdmin">
          <button className="admin-btn">Create Admin</button>
        </Link>
      </div>
      <MUIDataTable
        title={"Admin List"}
        data={data}
        columns={columns}
        options={options}
      />
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="modal-content logout-modal p-5">
          <p className="text-center mb-5 text-white">
            Are you sure do you want to delete ?
          </p>
          <div className="row">
            <div className="col-6">
              <button className="btn ok-btn" onClick={() => {
                handleDelete()
                setOpen(false)
              }}>Yes</button>
            </div>
            <div className="col-6">
              <button className="btn cancel-btn text-green" onClick={() => setOpen(false)}>No</button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default AdminList;
