import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: "login",
    initialState: {
        isLoggedIn: false,
        token: "",
        userType: "",
        emailId: "",
        mobileNumber: "",
        countryCode: ""
    },
    reducers: {
        loginSuccess: (state, action) => {
            const userDetails = action.payload;
            state.isLoggedIn = true;
            state.userType = userDetails?.user_type;
            state.token = userDetails?.access_token;
        },
        logoutSuccess: (state, action) => {
            state.isLoggedIn = false;
            state.userType = "";
            state.token = "";
            state.emailId = ""

        },
        loginStateUpadte: (state, action) => {
            const userDetails = action.payload;
            state.name = userDetails?.name;
            state.profileImage = userDetails?.profileImage;
        },
        registerData: (state, action) => {
            const data = action.payload
            state.emailId = data
        },
        registerExpertData: (state, action) => {
            const data = action.payload
            console.log(data)
            state.mobileNumber = data?.phone_number
            state.countryCode = data?.country_code
        }
    },
});

export const { loginSuccess, logoutSuccess, loginStateUpadte, registerData, registerExpertData } =
    loginSlice.actions;

export default loginSlice.reducer;
