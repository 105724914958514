import React, { useState } from 'react'
import Logo from '../../assets/images/logo-white.png'
import { resolvePath, useNavigate } from 'react-router-dom'
import { PasswordValidation } from '../../common/validate'
import { successMsg } from '../../containers/Toastify'
import { AdminResetPassword } from '../../common/Api'
import { useSelector } from 'react-redux'
const ChangePassword = () => {
    const { emailId } = useSelector((state) => state?.login);
    const navigate = useNavigate()
    const [password, setPassword] = useState({})
    const [viewPassword, setViewPassword] = useState()
    const [validation, setValidation] = useState({})
    const [matchError, setMatchError] = useState()
    const handleChange = (key, value) => {
        setPassword({
            ...password,
            [key]: value
        })
    }

    const handleEyeClick = (key, value) => {
        setViewPassword({
            ...viewPassword,
            [key]: value
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        let validate = {}
        validate.enteredPassword = PasswordValidation(password?.enteredPassword)
        validate.reEnteredPassword = PasswordValidation(password?.reEnteredPassword)
        setValidation(validate)
        const check = Object.values(validation).every((a) => a.status === true)
        if (check) {
            if (password?.enteredPassword === password?.reEnteredPassword) {
                const payload = {
                    email_id: emailId,
                    password: password?.enteredPassword
                }
                const response = await AdminResetPassword(payload)
                if (response && response?.statusCode === 200) {
                    successMsg(response?.message)
                    navigate("/admin-login")
                }
            } else {
                setMatchError("PAsswords do not match")
            }
        }
    }
    console.log(validation)



    return (
        <div className='login-bg justify-content-center'>
            <div className='forgot-password-screen'>
                <img className='fp-logo' src={Logo} alt='guy-with-laptop' />
                <h5 className='text-white text-center mb-4'>Change Password</h5>
                <div className='position-relative'>
                    <input
                        type={viewPassword?.enteredPassword ? 'text' : 'password'}
                        className='form-control login-input'
                        placeholder='Enter Password'
                        onChange={(e) => handleChange('enteredPassword', e.target.value)}
                        defaultValue={password?.enteredPassword}
                    />
                    {
                        viewPassword?.enteredPassword ?
                            <i
                                className="fa-solid fa-eye change-password-eye"
                                onClick={() => handleEyeClick("enteredPassword", false)}
                            ></i> :
                            <i className="fa-solid fa-eye-slash change-password-eye"
                                onClick={() => handleEyeClick("enteredPassword", true)}
                            ></i>
                    }
                </div>
                {validation?.enteredPassword?.message ?
                    <small className='validation-error'>
                        {`${validation?.enteredPassword?.message}`}
                    </small> : ""
                }
                <div className='position-relative mt-4'>
                    <input
                        type={viewPassword?.reEnteredPassword ? 'text' : 'password'}
                        className='form-control login-input'
                        placeholder='Re-enter your password'
                        onChange={(e) => handleChange('reEnteredPassword', e.target.value)}
                        defaultValue={password?.reEnteredPassword}
                    />
                    {
                        viewPassword?.reEnteredPassword ?
                            <i
                                className="fa-solid fa-eye change-password-eye"
                                onClick={() => handleEyeClick("reEnteredPassword", false)}
                            ></i> :
                            <i className="fa-solid fa-eye-slash change-password-eye"
                                onClick={() => handleEyeClick("reEnteredPassword", true)}
                            ></i>
                    }
                    {validation?.reEnteredPassword?.message ?
                        <small className='validation-error'>
                            {`${validation?.reEnteredPassword?.message}`}
                        </small> : ""
                    }
                    {matchError ?
                        <small className='validation-error'>
                            {matchError}
                        </small> : ""
                    }
                </div>
                <button
                    className='btn login-btn mt-4'
                    onClick={handleSubmit}
                >Submit</button>

            </div>
        </div>
    )
}

export default ChangePassword