import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PageContent from "./PageContent";
import Profile from "./Profile";
import { useSelector } from "react-redux";

const Layout = () => {
  const { userType } = useSelector((state) => state?.login);
  return (
    <div className="layout-container">
      <Header />
      <div className="content-body">
        <Sidebar />
        <PageContent className="main-page expert-main-page" />

        {userType === "user" ? <Profile /> : ""}
      </div>
    </div>
  );
};

export default Layout;
