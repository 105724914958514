import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/CustomModal";

const Dashboard = () => {
  const navigate = useNavigate();
  const filters = ["All", "Today", "1w", "1m", "3m", "1y"];
  const [currentFilter, setCurrentFilter] = useState(0);
  const handleNavigate1 = () => {
    navigate("/expert/expressTips");
  };
  const [open, setOpen] = useState(false);
  const handleNavigate2 = () => {
    navigate("/expert/tips");
  };
  const handleFilter = () => {
    setOpen(!open);
  };
  return (
    <div>
      <div className="expert_dashboard">
        <div className="filter_div d-flex justify-content-between">
          <div className="d-flex">
            {filters.map((item, index) => {
              return (
                <p
                  className={
                    currentFilter === index
                      ? "filter_text_active me-2"
                      : "filter_text me-2"
                  }
                  onClick={(e) => setCurrentFilter(index)}
                >
                  {item}
                </p>
              );
            })}
          </div>
          <div onClick={() => setOpen(true)}>
            <i class="fa-solid fa-calendar-days me-3"></i>
          </div>
        </div>
        <div className="row pe-3">
          <div className="col-lg-3">
            <div className="dash_card mb-3">
              <p className="count_text mb-0">576</p>
              <p className="dash_card_text mb-0">Total Tips</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dash_card mb-3">
              <p className="count_text mb-0">17.k</p>
              <p className="dash_card_text mb-0">Likes</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dash_card mb-3">
              <p className="count_text mb-0">100K</p>
              <p className="dash_card_text mb-0">Followers</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dash_card mb-3">
              <p className="count_text mb-0">10.2K</p>
              <p className="dash_card_text mb-0">Completed Tips</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dash_card mb-3">
              <p className="count_text mb-0">05</p>
              <p className="dash_card_text mb-0">Today Tips</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dash_card mb-3">
              <p className="count_text mb-0">$15</p>
              <p className="dash_card_text mb-0">Total Earnings</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dash_card mb-3">
              <p className="count_text mb-0">576</p>
              <p className="dash_card_text mb-0">Total Tips</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dash_card mb-3">
              <p className="count_text mb-0">400+</p>
              <p className="dash_card_text mb-0">Live Tips</p>
            </div>
          </div>
        </div>
        <div className="row pe-3 mt-2">
          <div className="col-lg-6">
            <div className="create_tip_card" onClick={handleNavigate1}>
              <p>+ Create Express Tips</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="create_tip_card" onClick={handleNavigate2}>
              <p>+ Create Tips</p>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        open={open}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className="p-5">
          <div className="dashboard_filter">
            <div className="filter_date_div">
              <div className="d-flex justify-content-between dash_filter_top">
                <p className="filter_para">Filter</p>
                <i
                  class="fa-solid fa-circle-xmark close-icon-dashboard my-auto"
                  onClick={handleFilter}
                ></i>
              </div>
              <div className="filter_div d-flex mt-2">
                <div className="d-flex flex-column me-3">
                  <p className="dash_filter_para mb-1">From Date</p>
                  <input type="date" className="dash_filter_date_input" />
                  <button className="filter_clear_btn mt-3">Clear</button>
                </div>
                <div className="d-flex flex-column">
                  <p className="dash_filter_para mb-1">To Date</p>
                  <input type="date" className="dash_filter_date_input" />
                  <button className="filter_submit_btn mt-3">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Dashboard;
