import React from 'react'
import Welcome from '../../features/public/Welcome'

const ExternalPage = () => {
    return (
        <>
            <Welcome />
        </>
    )
}

export default ExternalPage