import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import { thunk } from "redux-thunk";
import loginSlice from "./slice/loginSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

const persistConfig = {
    key: "trado",
    storage,
};

const rootReducer = combineReducers({
    login: loginSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
    devTools: process.env.NODE_ENV !== "production",
});

const persistor = persistStore(store);

export { store, persistor };
