import React, { useEffect, useState, useCallback } from 'react'
import { getAppConfiguration, updatetAppConfiguration } from '../../common/Api'
import { useSelector } from 'react-redux'
import { EmailValidation, NonEmptyValidation, PasswordValidation } from '../../common/validate'
import { errorMsg, successMsg } from "../../containers/Toastify"

const AppConfiguartion = () => {
    const { token } = useSelector((state) => state.login)
    const [data, setData] = useState({})
    const [validation, setValidation] = useState({})

    const getData = useCallback(async () => {
        const response = await getAppConfiguration(token)
        if (response && response?.statusCode === 200) {
            setData(response?.data)
        }
    }, [token])

    useEffect(() => {
        getData()
    }, [])


    const hanadleChange = (value, key) => {
        setData({
            ...data,
            [key]: value
        })
    }

    const handleUpdate = async (e) => {
        e.preventDefault()

        const validate = {}
        validate.zerodha_access_token = NonEmptyValidation(data?.zerodha_access_token)
        validate.zerodha_api_key = NonEmptyValidation(data?.zerodha_api_key)
        validate.zerodha_api_secret_key = NonEmptyValidation(data?.zerodha_api_secret_key)
        validate.zerodha_password = NonEmptyValidation(data?.zerodha_password)
        validate.zerodha_redirect_url = NonEmptyValidation(data?.zerodha_redirect_url)
        validate.zerodha_totp_secret_key = NonEmptyValidation(data?.zerodha_totp_secret_key)
        validate.zerodha_user_name = NonEmptyValidation(data?.zerodha_user_name)
        validate.email_id = EmailValidation(data?.email_id)
        validate.password = NonEmptyValidation(data?.password)
        setValidation(validate)

        const checkIsValid = Object.values(validate).every((opt) => opt.status === true)
        console.log(checkIsValid)
        if (checkIsValid) {
            const payload = {
                zerodha_api_key: data?.zerodha_api_key,
                zerodha_api_secret_key: data?.zerodha_api_secret_key,
                zerodha_redirect_url: data?.zerodha_redirect_url,
                zerodha_access_token: data?.zerodha_access_token,
                zerodha_user_name: data?.zerodha_user_name,
                zerodha_password: data?.zerodha_password,
                zerodha_totp_secret_key: data?.zerodha_totp_secret_key,
                email_id: data?.email_id,
                password: data?.password
            }
            const response = await updatetAppConfiguration(payload, token)
            console.log(response)
            if (response && response?.statusCode === 200) {
                successMsg(response?.message)
            }
        } else {
            errorMsg("Fill the all required fields")
        }
    }

    return (
        <div className='admin-content-body'>
            <div className='admin-form-heading d-flex justify-content-between align-items-center'>
                <p className='mb-0'>App Configuration</p>
                <button className='admin-btn' onClick={handleUpdate}>Update</button>
            </div>
            <div className='Admin-form-content'>
                <div className=''>
                    <p className='admin-form-subheading'>Zerodha Credentials </p>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Zerodha API Key</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.zerodha_api_key}
                                onInput={(e) => hanadleChange(e.target.value, 'zerodha_api_key')}
                            />
                            {validation?.zerodha_api_key?.message ?
                                <small className='validation-error'>
                                    {`Zerodha API Key ${validation?.zerodha_api_key?.message}`}
                                </small> : ""
                            }
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Zerodha API Secret Key</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.zerodha_api_secret_key}
                                onInput={(e) => hanadleChange(e.target.value, 'zerodha_api_secret_key')}
                            />
                            {validation?.zerodha_api_secret_key?.message ?
                                <small className='validation-error'>
                                    {`Zerodha API Scret Key ${validation?.zerodha_api_secret_key?.message}`}
                                </small> : ""
                            }
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Zerodha Redirect Url</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.zerodha_redirect_url}
                                onInput={(e) => hanadleChange(e.target.value, 'zerodha_redirect_url')}
                            />
                            {validation?.zerodha_redirect_url?.message ?
                                <small className='validation-error'>
                                    {`Zerodha Redirect URL ${validation?.zerodha_redirect_url?.message}`}
                                </small> : ""
                            }
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Zerodha Access Token</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.zerodha_access_token}
                                onInput={(e) => hanadleChange(e.target.value, 'zerodha_access_token')}
                            />
                            {validation?.zerodha_access_token?.message ?
                                <small className='validation-error'>
                                    {`Zerodha Access Token ${validation?.zerodha_access_token?.message}`}
                                </small> : ""
                            }
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Zerodha User name</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.zerodha_user_name}
                                onInput={(e) => hanadleChange(e.target.value, 'zerodha_user_name')}
                            />
                            {validation?.zerodha_user_name?.message ?
                                <small className='validation-error'>
                                    {`Zerodha User Name ${validation?.zerodha_user_name?.message}`}
                                </small> : ""
                            }
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Zerodha Password</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.zerodha_password}
                                onInput={(e) => hanadleChange(e.target.value, 'zerodha_password')}
                            />
                            {validation?.zerodha_password?.message ?
                                <small className='validation-error'>
                                    {`Zerodha password ${validation?.zerodha_password?.message}`}
                                </small> : ""
                            }
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Zerodha T-OTP Secret Key</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.zerodha_totp_secret_key}
                                onInput={(e) => hanadleChange(e.target.value, 'zerodha_totp_secret_key')}
                            />
                            {validation?.zerodha_totp_secret_key?.message ?
                                <small className='validation-error'>
                                    {`Zerodha T-OTP secret key ${validation?.zerodha_totp_secret_key?.message}`}
                                </small> : ""
                            }
                        </div>
                    </div>
                </div>
                <div className=''>
                    <p className='admin-form-subheading'>Sender Mail Credentials </p>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Sender Mail Address</label><br />
                            <input
                                type='email'
                                className='form-control admin-panel-input'
                                defaultValue={data?.email_id}
                                onInput={(e) => hanadleChange(e.target.value, 'email_id')}
                            />
                            {validation?.email_id?.message ?
                                <small className='validation-error'>
                                    {`E-mail ${validation?.email_id?.message}`}
                                </small> : ""
                            }
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='admin-form-container'>
                            <label className='admin-panel-label'>Sender mail Password</label><br />
                            <input
                                type='text'
                                className='form-control admin-panel-input'
                                defaultValue={data?.password}
                                onInput={(e) => hanadleChange(e.target.value, 'password')}
                            />
                        </div>
                        {validation?.password?.message ?
                            <small className='validation-error'>
                                {`Password ${validation?.email_id?.message}`}
                            </small> : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppConfiguartion